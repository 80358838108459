import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { HrpMultiselectOptionComponent } from '../multiselect-option.component';

export function onKeydownFn<T>({
  event,
  keyManager,
}: {
  event: KeyboardEvent;
  keyManager: ActiveDescendantKeyManager<HrpMultiselectOptionComponent<T>>;
}) {
  switch (event.key) {
    case 'Enter':
    case 'Delete':
      keyManager?.activeItem?.onToggleOption();
      event.stopPropagation();
      event.preventDefault();
      break;
    case 'ArrowUp':
    case 'ArrowDown':
      keyManager.onKeydown(event);
      keyManager.activeItem?.scrollIntoView();
      break;
  }
}