import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  inject,
} from '@angular/core';
import { ResponsiveService } from '@core/services';

@Component({
  selector: 'page-break-time-day-categories',
  templateUrl: 'break-time-day-categories.component.html',
  styleUrls: ['break-time-day-categories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class BreakTimeDayCategoriesComponent {
  @Output() openDialog = new EventEmitter<void>();
  responsive = inject(ResponsiveService);
}
