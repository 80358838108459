import {
  Component,
  ChangeDetectionStrategy,
  inject,
  DestroyRef,
  Output,
  EventEmitter,
  computed,
  effect,
  untracked,
  signal,
  WritableSignal,
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  ContributorInfoDto,
  ContributorScheduleDto,
  OrganizationalUnitDto,
  QuerySettingsDto,
  ScheduleItemInfoDto,
} from '@swagger/humanresources';
import { combineLatest, debounceTime, firstValueFrom, map } from 'rxjs';
import { ResponsiveService } from '@core/services';
import { CalendarEntry, CalendarMode } from '@shared/plan-defs';
import { DateAdapter, WeekDay } from '@paragondata/ngx-ui/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ComponentStore } from '@ngrx/component-store';
import { PlanGroupStore } from '../store/plan-group/plan-group.store';
import { isEmpty, isEqual } from 'lodash';
import { DateService } from '@shared/utils';
import { BreakTimeService } from '@shared/break-time';
import {
  CalendarFilterService,
  CalendarGroupBodyDayComponent,
  CalendarGroupBodyMonthComponent,
  CalendarGroupBodyWeekComponent,
  CalendarGroupFilterComponent,
} from '../shared';
import { SessionService } from '@core/auth';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@jsverse/transloco';
import { ParProgressModule } from '@paragondata/ngx-ui/progress';
import { FilterOverlayComponent } from '@shared/ui';
import {
  CalendarNavigationMobileWeekComponent,
  CalendarStepperComponent,
} from '@shared/plan-navigation';
import { Filter, IFilter } from '@paragondata/ngx-ui/filter';
import { HorizontalScrollComponent } from '@shared/horizontal-scroll';
import { ChipComponent } from '@shared/chip';
import moment from 'moment';

export interface PagePlanGroupState {
  loading: boolean;
  entries: { [key: string]: CalendarEntry[] };
  schedules: ContributorScheduleDto[];
  employees: ContributorInfoDto[];
  date: Date;
  mode: CalendarMode;
  visibleMonthCompactCalendar: Date;
  firstDayOfWeek: Date;
  lastDayOfWeek: Date;
  scheduleId: string;
  filterActive: boolean;
  queryParams: Params;
  locked: boolean;
  skipForRange: { [key: string]: number };
}

@Component({
  selector: 'page-plan-group',
  templateUrl: 'plan-group-page.component.html',
  styleUrls: ['plan-page.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CalendarGroupFilterComponent,
    CalendarGroupBodyMonthComponent,
    CalendarGroupBodyDayComponent,
    CalendarGroupBodyWeekComponent,
    CalendarNavigationMobileWeekComponent,
    CalendarStepperComponent,
    TranslocoModule,
    ParProgressModule,
    FilterOverlayComponent,
    HorizontalScrollComponent,
    ChipComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PagePlanGroupComponent extends ComponentStore<PagePlanGroupState> {
  private destroyRef = inject(DestroyRef);
  @Output() employeeListChanged: EventEmitter<void> = new EventEmitter<void>();
  calendarGroupBreakTimeDayService = inject(BreakTimeService);
  calendarFilterService = inject(CalendarFilterService);
  private _session = inject(SessionService);

  private store = inject(PlanGroupStore);
  private entries$ = this.select((s) => s.entries);
  private employees$ = this.select((s) => s.employees);
  private schedules$ = this.select((s) => s.schedules);
  private loading$ = this.select((s) => s.loading);
  private date$ = this.select((s) => s.date);
  private mode$ = this.select((s) => s.mode);
  private visibleMonthCompactCalendar$ = this.select(
    (s) => s.visibleMonthCompactCalendar
  );
  private firstDayOfWeek$ = this.select((s) => s.firstDayOfWeek);
  private lastDayOfWeek$ = this.select((s) => s.lastDayOfWeek);
  private scheduleId$ = this.select((s) => s.scheduleId);
  private filterActive$ = this.select((s) => s.filterActive);
  private locked$ = this.select((s) => s.locked);
  private skipForRange$ = this.select((state) => state.skipForRange);
  private defaultFilter$ = this.store.defaultFilter$;
  private params$ = this.activatedRoute.queryParams.pipe(
    map((params) => params)
  );

  get weekDayNames() {
    return this.dateAdapter.getWeekdaysShort(WeekDay.Monday);
  }

  employees = toSignal(this.employees$);
  $filter = this.store.$filter;
  loading = toSignal(this.loading$);
  response = toSignal(this.store.response$);
  $maxHits = toSignal(this.store.maxHits$);
  schedules = toSignal(this.schedules$);
  error = toSignal(this.store.error$);
  scheduleId = toSignal(this.scheduleId$);
  entries = toSignal(this.entries$);
  flatEntries = computed(() =>
    Object.entries(this.entries()).flatMap((e) => e[1])
  );
  date = toSignal(this.date$);
  mode = toSignal(this.mode$);
  visibleMonthCompactCalendar = toSignal(this.visibleMonthCompactCalendar$);
  firstDayOfWeek = toSignal(this.firstDayOfWeek$);
  lastDayOfWeek = toSignal(this.lastDayOfWeek$);
  filterActive = toSignal(this.filterActive$);
  $queryParams = this.store.$queryParams;
  locked = toSignal(this.locked$);
  $params = toSignal(this.params$);
  $defaultFilter = toSignal(this.defaultFilter$);

  screenWidth$ = this.responsive.windowResize$.pipe(
    takeUntilDestroyed(this.destroyRef),
    map(() => window.innerWidth)
  );
  weekDateEnd = this.dateAdapter.getLastDateOfWeek(this.date());
  loadMore: boolean = false;
  rows = signal<ContributorInfoDto[]>([]);
  weeksHeading = signal<string>('');

  $filteredOrganizationalUnits: WritableSignal<OrganizationalUnitDto[]> =
    signal([]);
  $skipForRange = toSignal(this.skipForRange$);

  employeesEffect = effect(() => {
    const employees = this.employees();
    untracked(() => {
      this.rows.update((rows) => {
        // Add employees to rows
        rows = [];
        for (const employee of employees) {
          rows.push(employee);
        }

        // If rows are less than 10, fill with null objects
        while (rows.length < 16) {
          rows.push(null);
        }
        return rows;
      });
    });
  });

  defaultFilterEffect = effect(() => {
    const defaultFilter = this.$defaultFilter();
    const filter = this.$filter();
    if (!isEmpty(defaultFilter) && isEmpty(filter) && isEmpty(this.$params())) {
      untracked(() => {
        this.initDefaultSetups(defaultFilter);
      });
    }
  });

  loadQueryEffect = effect(() => {
    const filter = this.$filter();
    const defaultFilter = this.$defaultFilter();

    untracked(() => {
      if (!isEmpty(filter)) {
        this.querySchedules();

        if (
          !isEqual(
            this.createFilter(filter).getQueryParams(),
            this.createFilter(defaultFilter).getQueryParams()
          )
        ) {
          this.patchState({ filterActive: true });
        }
      }
    });
  });

  queryParamsEffect = effect(() => {
    const queryParams = this.$queryParams();

    if (!isEmpty(queryParams)) {
      untracked(() => {
        this.navigate(queryParams);
      });
    }
  });

  weekEffect = effect(() => {
    const mode = this.mode();
    const date = this.date();
    untracked(() => {
      if (mode === 'week') {
        const monday = moment(this.date()).startOf('week').toDate();
        const sunday = moment(this.date()).endOf('week').toDate();
        //determine if the week has two months
        if (moment(monday).month() !== moment(sunday).month()) {
          const month1 = moment(monday).format('MMMM');
          const month2 = moment(sunday).format('MMMM');
          const year = moment(monday).format('YYYY');
          this.weeksHeading.set(`${month1} / ${month2} ${year}`);
        } else {
          this.weeksHeading.set(moment(monday).format('MMMM yyyy'));
        }
      }
    });
  });


  get isInfoRole() {
    return this._session.isInfo;
  }

  constructor(
    public responsive: ResponsiveService,
    private dateAdapter: DateAdapter,
    public dateService: DateService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    super({
      date: new Date(),
      entries: {},
      schedules: [],
      employees: [],
      mode: 'month',
      visibleMonthCompactCalendar: new Date(),
      firstDayOfWeek: new Date(),
      lastDayOfWeek: new Date(),
      loading: true,
      scheduleId: '',
      filterActive: false,
      queryParams: undefined,
      locked: false,
      skipForRange: {},
    });
    this.store.clearData();
    this.store.queryFilter();

    // #2706 If Mobile, set Mode initially to Month
    effect(() => {
      if (this.responsive.$isMobile()) {
        untracked(() => {
          this.setMode('month');
        });
      }
    });

    effect(() => {
      const queryParams = this.$params();
      if (!isEmpty(queryParams) && !isEmpty(this.$defaultFilter())) {
        untracked(async () => {
          this.updateFilterValues(queryParams);
          const ous =
            await this.calendarFilterService.getFilteredOrganizationalUnitsByStrings(
              queryParams.filter_ou.split(';')
            );
          this.$filteredOrganizationalUnits.set(ous);
        });
      }
    });
  }

  initDefaultSetups(defaultFilter: QuerySettingsDto) {
    const filter = this.createFilter(defaultFilter);
    this.store.updateFilter(filter);
    this.setToday();
    this.setMode(this.responsive.$isMobile() ? 'day' : 'month');

    this.setSelectedOus(filter.getQueryParams().filter_ou);

    this.initializeScheduleLogic();
  }

  initializeScheduleLogic() {
    this.store.response$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response) => {
        this.patchState({ schedules: response });
      });

    // Build calendar entries for ui from schedules response
    this.schedules$
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100))
      .subscribe((response) => {
        if (this.error() === null) {
          this.buildEntries(response);
          setTimeout(() => this.patchState({ loading: false }), 200);
        }
      });

    combineLatest([this.date$, this.mode$])
      .pipe(takeUntilDestroyed(this.destroyRef), debounceTime(500))
      .subscribe(([date, mode]) => {
        this.patchState({ loading: true });
      });

    //reset skipForRange when filter changed
    this.resetSkipForRange();
  }

  updateFilterValues(params: Params) {
    const filter = this.createFilter(this.$defaultFilter());
    filter.fromQueryParams({
      main_qs: undefined,
      filter_ou: params.filter_ou,
    } as Params);
    this.store.updateFilter(filter as QuerySettingsDto);
    if (params.date) {
      this.setSelectedDate(new Date(params.date));
    }

    if (params.filter_ou) {
      this.setSelectedOus(params.filter_ou);
    }
    this.initializeScheduleLogic();
  }

  buildEntries(response: any) {
    let empl = this.loadMore ? this.employees() : [];
    let calendarEntries = {};

    for (const res of response) {
      // Add employees to the list if they aren't already present
      if (!empl.find((e) => e.uId === res.contributor.uId)) {
        empl = [...empl, res.contributor].sort((a, b) =>
          a.lastName?.localeCompare(b.lastName)
        );
      }

      if (res?.schedules && Array.isArray(res?.schedules)) {
        const entries = res.schedules?.flatMap((sched) =>
          sched.items?.map((item) =>
            this.createCalendarEntry(item, res.contributor)
          )
        );

        // Add entries only if schedule items are present
        if (
          res?.schedules.some(
            (sched) => Array.isArray(sched.items) && sched.items.length > 0
          )
        ) {
          calendarEntries[res.contributor.uId] = entries;
        } else {
          // Add an empty array for contributors with schedules but no items
          calendarEntries[res.contributor.uId] = [];
        }
      } else {
        // Add an empty array for contributors with no schedules
        calendarEntries[res.contributor.uId] = [];
      }
    }

    this.patchState({
      employees: empl,
      entries: { ...(this.loadMore ? this.entries() : {}), ...calendarEntries },
    });
  }

  createCalendarEntry(item: ScheduleItemInfoDto, employee: ContributorInfoDto) {
    return {
      id: item.uId || '',
      start: new Date(item.start),
      end: new Date(item.stop),
      item: item,
      employee,
    };
  }

  async loadMoreSchedules() {
    if (this.loading()) {
      return;
    }
    this.patchState({ loading: true });
    const skipForRange = await firstValueFrom(this.skipForRange$);
    const { start, end } = this.getDateRange();
    let skip =
      skipForRange[`${start.toDateString()}-${end.toDateString()}`] || 0;
    //nur nachladen, wenn noch nicht alles geladen wurde in diesem Zeitraum
    if (skip < this.$maxHits() && this.rows().length < this.$maxHits()) {
      skip += 20;
      this.patchSkipForRange(skipForRange, start, end, skip);
      this.querySchedules(skip);
    } else {
      this.patchState({ loading: false });
    }
  }

  querySchedules(skip: number = 0) {
    this.loadMore = skip > 0;
    this.store.query(this.date(), this.mode(), skip);
  }

  visibleMonthCompactCalendarChanged(date: Date) {
    this.patchState({ visibleMonthCompactCalendar: date });
  }

  navigate(params: Params) {
    this.router.navigate([], {
      queryParams: { ...this.activatedRoute.snapshot.queryParams, ...params },
    });
  }

  updateQueryParams(params: Params) {
    this.store.updateQueryParams(params);
  }

  navigateDays(days: number, mode: CalendarMode = this.mode()) {
    this.patchState({ loading: true });
    const newDate = this.getNewSelectedDateAfterNavigationOrModeSwitch(
      this.date(),
      days,
      this.mode(),
      mode
    );
    this.setSelectedDate(newDate);
  }

  getNewSelectedDateAfterNavigationOrModeSwitch(
    currentDate: Date,
    days: number,
    oldMode: CalendarMode,
    newMode: CalendarMode
  ): Date {
    if (oldMode === newMode) {
      //navigation innerhalb des selben Modus
      if (newMode !== 'month') {
        //merken bei welchem Tag oder Woche man ist, damit man bei Moduswechel wieder dorthin zurückkommt (solange man sich im selben Monat befindet wie zuvor)
        this.patchState({ locked: true });
      } else {
        //wenn der Monat gewechselt wird, dann nicht mehr merken, dann kommt das Standardverhalten zum tragen
        this.patchState({ locked: false });
      }
      return moment(currentDate).add(days, 'days').toDate();
    } else {
      //Moduswechsel
      //erst Sonderfälle abfangen, dann Standardverhalten
      if (newMode === 'day' && oldMode === 'month') {
        return this.switchFromMonthToDay(currentDate);
      } else if (newMode === 'day' && oldMode === 'week') {
        return this.switchFromWeekToDay(currentDate);
      } else if (newMode === 'week' && oldMode === 'month') {
        return this.switchFromMonthToWeek(currentDate);
      } else {
        return currentDate;
      }
    }
  }

  setToday() {
    this.setSelectedDate(this.dateAdapter.today());
  }

  setSelectedDate(date: Date) {
    this.patchState({ date });
    if (this.$params().date !== date.toISOString()) {
      this.updateQueryParams({ date: date.toISOString() });
    }
  }

  dateClicked(date: Date) {
    this.patchState({ locked: true });
    const newDate = this.getNewSelectedDateAfterNavigationOrModeSwitch(
      date,
      0,
      this.mode(),
      this.mode()
    );
    this.setSelectedDate(newDate);
  }

  setSelectedOus(ousString: string) {
    this.updateQueryParams({ filter_ou: ousString });
  }

  setCurrentWeek() {
    const today = new Date();
    const selectedDate = this.dateAdapter.getFirstDateOfWeek(
      today,
      WeekDay.Monday
    );
    this.weekDateEnd = this.dateAdapter.addCalendarDays(today, 6);
    this.setSelectedDate(selectedDate);
  }

  setMode(mode: CalendarMode, fromUi: boolean = false) {
    this.navigateDays(0, mode);

    // Reset entries and employees if triggered from UI
    if (fromUi) {
      this.resetEntriesAndEmployees();
    }
    if (this.mode() !== mode) {
      this.patchState({ mode });
      this.updateQueryParams({ mode });
    }
  }

  private switchFromMonthToDay(date: Date): Date {
    let newDate = moment(date).startOf('month').toDate();
    if (this.locked()) {
      return date;
    }
    if (
      moment(date).isSame(moment(), 'month') &&
      moment(date).isSame(moment(), 'year')
    ) {
      //if today is in the same month go to today
      newDate = moment().toDate();
    }
    if (moment(newDate).day() === 0) {
      //if date is a sunday, go to the next monday
      return moment(newDate).add(1, 'day').toDate();
    } else {
      return newDate;
    }
  }

  private switchFromMonthToWeek(date: Date): Date {
    if (this.locked()) {
      return date;
    }
    if (moment(date).isSame(moment(), 'month')) {
      return moment().toDate();
    }
    let firstDayOfMonth = moment(date).startOf('month');
    // Check if the first day of the month is Saturday (6) or Sunday (0)
    if (firstDayOfMonth.day() === 6) {
      // Saturday -> Go to the next Monday
      firstDayOfMonth.add(2, 'days');
    } else if (firstDayOfMonth.day() === 0) {
      // Sunday -> Go to the next Monday
      firstDayOfMonth.add(1, 'day');
    }
    return firstDayOfMonth.toDate();
  }

  private switchFromWeekToDay(date: Date): Date {
    //if week is current week, go to today
    if (moment(date).isSame(moment(), 'week')) {
      return moment().toDate();
    }
    if (this.locked()) {
      return date;
    } else {
      //go to monday
      let monday = moment(date).startOf('week').toDate();
      return monday;
    }
  }

  onCompactCalendar(date: Date) {
    this.setSelectedDate(date);
  }

  clearCalendarEntries() {
    this.patchState({ entries: {} });
  }

  filterClosed({ active, reload }: { active: boolean; reload: boolean }) {
    this.patchState({ filterActive: active, loading: reload });
  }

  updateSchedules(schedules: ContributorScheduleDto[]) {
    this.patchState({ schedules });
  }

  // Helper to get date range based on the mode (month/week/day)
  private getDateRange(): { start: Date; end: Date } {
    const start = this.date();
    let stop;

    if (this.mode() === 'month') {
      // Extend the month range for calendar view (get full weeks)
      stop = this.dateService.getExtendedMonth({ selectedDate: start }).stop;
    } else {
      stop = this.dateService.getTimespan({
        selectedDate: start,
        mode: this.mode(),
      });
    }

    const end = this.dateAdapter.addCalendarDays(start, stop);
    return { start, end };
  }

  private patchSkipForRange(
    skipForRange: any,
    start: Date,
    end: Date,
    skip: number
  ): void {
    this.patchState({
      skipForRange: {
        ...skipForRange,
        [`${start.toDateString()}-${end.toDateString()}`]: skip,
      },
    });
  }

  private resetSkipForRange(): void {
    this.patchState({ skipForRange: {} });
  }

  private resetEntriesAndEmployees(): void {
    this.patchState({ entries: {}, employees: [], loading: true });
  }

  private createFilter(filter: QuerySettingsDto): Filter {
    return Filter.create(filter as IFilter, {
      combineInputTypeBoolChildOptions: true,
    });
  }
}
