<par-form-field
  [showErrors]="showError"
  [errorText]="errorText"
  [control]="control"
>
  <label [for]="meta.key" class="label">
    {{ meta.name }}{{ meta.validators?.required ? "*" : "" }}
  </label>

  <hrp-multiselect
    #multiselect
    class="max-w-[34.5rem]"
    [id]="meta.key"
    [formControl]="control"
    [displayNameFn]="displayNameFn(meta.options)"
    [placeholder]="'Auswahl'"
  >
    @if (meta?.options?.length > 10) {
    <hrp-multiselect-searchbar></hrp-multiselect-searchbar>
    } @for(option of meta.options | search : multiselect?.$search()?.$query() :
    ['key']; track option.value) {
    <hrp-multiselect-option [value]="option.value">
      {{ option.key }}
    </hrp-multiselect-option>
    }
  </hrp-multiselect>
</par-form-field>
