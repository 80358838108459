@for (process of processes; let i = $index; let last = $last; track process.id) {
<app-process [process]="process"></app-process>
@if (i !== 0 && !last) {
<span
  class="h-[50%] rounded-full border-solid border border-hrp-subtle-500"
></span>
}}

<button *ngIf="!responsive.$isMobile()" class="flex items-center justify-center" (click)="logout()" data-what="logout-button">
  <span class="material-symbols-rounded"> logout </span>
</button>
