import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject,
  signal,
  WritableSignal,
} from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { AuthService } from '@core/auth';
import { ResponsiveService } from '@core/services';
import { AppAccountService } from '@features/account/bussines';
import { TranslocoModule } from '@jsverse/transloco';
import { ParProgressModule } from '@paragondata/ngx-ui/progress';
import { SelectModule } from '@paragondata/ngx-ui/select';
import moment from 'moment';
import { interval } from 'rxjs';
import packageInfo from 'packageJson';

@Component({
  selector: 'page-account-information',
  templateUrl: 'information.component.html',
  styleUrls: ['information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, ParProgressModule, SelectModule, TranslocoModule],
  standalone: true,
})
export class PageAccountInformationComponent {
  private _responsive = inject(ResponsiveService);
  private _userAccount = inject(AppAccountService);
  private _authService = inject(AuthService);

  userData$ = this._userAccount.myUserAccount;

  $loading: WritableSignal<boolean> = signal(false);
  private $interval = toSignal(interval(1000));

  $accessTokenClaims = computed(() => {
    this.$interval();
    return this._authService.getClaims();
  });

  $accessTokenExpiresAt = computed(() => {
    const claims = this.$accessTokenClaims();
    const date = moment.unix(claims.exp)?.toDate();
    return date instanceof Date && !isNaN(date.getTime()) ? date : undefined;
  });

  $accessTokenExpiresIn = computed(() => {
    const accessTokenExpiresAt = this.$accessTokenExpiresAt();
    return moment(accessTokenExpiresAt).fromNow();
  });

  get $isDesktop() {
    return this._responsive.$isDesktop;
  }

  get appVersion() {
    return packageInfo.version;
  }

  constructor() {
    this._simulateLoadingAnimation();
  }

  async refreshAccessToken() {
    if (await this._authService.refresh()) {
      console.log('Token refreshed');
    } else {
      console.log('Token refresh failed');
    }
  }

  private _simulateLoadingAnimation() {
    this.$loading.set(true);
    setTimeout(() => {
      this.$loading.set(false);
    }, 150);
  }
}
