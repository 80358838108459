import {
  provideTransloco,
  TranslocoModule
} from '@jsverse/transloco';
import { NgModule } from '@angular/core';
import { TranslocoHttpLoader } from './transloco-loader';
import { environment } from '../environments/environment';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { isDev } from '@ngneat/elf';

@NgModule({
  exports: [ TranslocoModule ],
  providers: [
    provideHttpClient(withFetch()),
      provideTransloco({
        config: {
          availableLangs: ['de', 'en'],
          defaultLang: 'de',
          // Remove this option if your application doesn't support changing language in runtime.
          reRenderOnLangChange: true,
          prodMode: !isDev(),
        },
        loader: TranslocoHttpLoader,
      }),
  ],
})
export class TranslocoRootModule {}
