import { Component, ChangeDetectionStrategy, signal } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { UiFormFieldModule } from '@paragondata/ngx-ui/form-field';
import { SelectModule } from '@paragondata/ngx-ui/select';
import { DynamicFormControlOption, DynamicFormControlTemplateDirective } from '@shared/dynamic-form';
import { DynamicFormControlDirective } from '../../dynamic-form-control.directive';
import { AbstractDynamicFormControl } from '../../abstact-dynamic-form-control.directive';
import {
  HrpMultiselectComponent,
  HrpMultiselectOptionComponent,
  HrpMultiselectSearchbarComponent,
} from '@shared/ui/multiselect';
import { SearchPipe } from '@shared/utils';

@Component({
  selector: 'dynamic-form-multiselect-control',
  templateUrl: 'multiselect-control.component.html',
  styleUrls: ['multiselect-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    DynamicFormControlTemplateDirective,
    ReactiveFormsModule,
    UiFormFieldModule,
    SelectModule,
    HrpMultiselectComponent,
    HrpMultiselectOptionComponent,
    HrpMultiselectSearchbarComponent,
    SearchPipe,
  ],
  providers: [
    {
      provide: DynamicFormControlDirective,
      useExisting: DynamicFormMultiselectControlComponent,
    },
  ],
})
export class DynamicFormMultiselectControlComponent extends AbstractDynamicFormControl {
  displayNameFn = (options: DynamicFormControlOption[]) => (selected: any) => {
    return options?.find((o) => o.value === selected)?.key ?? selected;
  };
}
