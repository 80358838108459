import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function breakTimePossibleValidator(
  controlName1: string,
  controlName2: string,
  possibleBreakTimeRange: { start: string; stop: string }[]
): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const control1 = group.get(controlName1);
    const control2 = group.get(controlName2);

    if (!control1 || !control2) {
      return null;
    }

    const time1 = control1.value;
    const time2 = control2.value;

    if (!time1 || !time2) {
      return null;
    }

    const isPossible = possibleBreakTimeRange.some((range) => {
      return time1 >= range.start && time2 <= range.stop;
    });

    if (!isPossible) {
      return { possibleBreakTime: true };
    }

    return null;
  };
}
