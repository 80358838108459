import { CommonModule, WeekDay } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  effect,
  inject,
  input,
  untracked,
} from '@angular/core';
import { DateAdapter } from '@paragondata/ngx-ui/core';
import { TranslocoModule } from '@jsverse/transloco';
import { CalendarBodyBase } from './calendar-body-base';
import { ParProgressModule } from '@paragondata/ngx-ui/progress';
import { BehaviorSubject } from 'rxjs';
import {
  CalendarGeneralEntryComponent,
  ParScrollContainerDirective,
} from '@shared/ui';
import { ContributorInfoDto } from '@swagger/humanresources';
import { CalendarTimeIntervalFilterPipe, DateService } from '@shared/utils';
import { ResponsiveService } from '@core/services';
import { CalendarEmployeeFilterPipe } from '@features/calendar/business';
import { CalendarEntry, CalendarMode } from '@shared/plan-defs';
import {
  CalendarEntriesDayFilterService,
  CalendarEntriesFilterService,
} from '@features/my-plan';

@Component({
  selector: 'calendar-group-body-month',
  templateUrl: 'calendar-group-body-month.component.html',
  styleUrls: ['calendar-group-body.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    CalendarGeneralEntryComponent,
    TranslocoModule,
    CalendarTimeIntervalFilterPipe,
    ParProgressModule,
    ParScrollContainerDirective,
    CalendarEmployeeFilterPipe,
  ],
})
export class CalendarGroupBodyMonthComponent
  extends CalendarBodyBase
  implements OnChanges
{
  @Input() loading: boolean = false;
  @Output() loadMoreSchedules: EventEmitter<void> = new EventEmitter<void>();

  today = this.dateAdapter.today();

  @Input() date: Date = new Date();
  @Input() entries: CalendarEntry[] = [];
  employeeList = input<ContributorInfoDto[]>([]);
  @Output() modeChanged: EventEmitter<CalendarMode> =
    new EventEmitter<CalendarMode>();
  @Output() dateChanged: EventEmitter<Date> = new EventEmitter();

  @ViewChild('scrollContainer', { static: true })
  scrollContainer: ParScrollContainerDirective;

  filteredEntries: CalendarEntry[] = [];
  filteredEntries$ = new BehaviorSubject<CalendarEntry[]>([]);
  filteredDayEntries: { [key: string]: CalendarEntry[] } = {};
  filteredDayEntries$ = new BehaviorSubject<{
    [key: string]: CalendarEntry[];
  }>({});
  filteredEmployeeEntries: {
    [key: string]: { [key: string]: CalendarEntry[] };
  } = {};
  filteredEmployeeEntries$ = new BehaviorSubject<{
    [key: string]: { [key: string]: CalendarEntry[] };
  }>(undefined);
  employeeList$ = new BehaviorSubject<ContributorInfoDto[]>([]);

  weeks = this.dateAdapter.getDatesAndCalendarWeeksForMonth(this.date);
  weekDayNames = this.dateAdapter.getWeekdaysShort(WeekDay.Monday);

  constructor(
    private dateAdapter: DateAdapter,
    private cdr: ChangeDetectorRef,
    public dateService: DateService,
    public responsive: ResponsiveService,
    public entriesFilterService: CalendarEntriesFilterService,
    public entriesDayFilterService: CalendarEntriesDayFilterService
  ) {
    super();
  }

  employeeListEffect = effect(() => {
    const employeeList = this.employeeList();
    untracked(() => {
      if (employeeList.length <= 20) {
        // Scroll To Top after Data Changed (e.g. Filter)
        this.scrollContainer.nativeElement.scrollTop = 0;
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      }
    });
  });

  ngOnChanges({ date, entries }: SimpleChanges): void {
    if (date?.currentValue) {
      this.weeks = this.dateAdapter.getDatesAndCalendarWeeksForMonth(this.date);
    }

    if (entries?.currentValue) {
      this.filteredEntries = this.entriesFilterService.transform(
        this.entries,
        'month',
        this.date
      );

      for (let week of this.weeks) {
        for (let i = 0; i < this.weekDayNames.length; i++) {
          const dayEntries = this.entriesDayFilterService.transform(
            this.filteredEntries,
            week.dates[i]
          );
          this.filteredDayEntries[week.dates[i].toISOString()] = dayEntries;
        }
      }

      this.filteredDayEntries$.next(this.filteredDayEntries);
      this.cdr.markForCheck();
    }
  }

  clickOnCalendarDay(date: Date): void {
    this.modeChanged.emit('day');
    this.dateChanged.emit(date);
  }

  onReachEnd(): void {
    this.triggerDataLoading();
  }

  triggerDataLoading(): void {
    this.loadMoreSchedules.emit();
  }
}
