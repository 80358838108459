<ng-content></ng-content>
<button
  class="hrp-multiselect-selected-option-tag__close-button"
  tabindex="-1"
  type="button"
  (click)="$onRemove.emit(); $event.preventDefault(); $event.stopPropagation()"
>
  <ng-icon
    name="matCloseRound"
    class="hrp-multiselect-selected-option-tag__close-icon"
  ></ng-icon>
</button>
