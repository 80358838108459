/**
 * Experimental
 * - Propagiert das Click-Event an das Element unter dem Mauszeiger, wenn auf das Backdrop geklickt wird
 * - Wenn das Element, dass den Backdrop selbst ausgelöst hat (self) geklickt wird, soll das Click-Event nicht propagiert werden
 * 
 * @param mouseEvent MouseEvent - MausEvent bestimmt den Target Element
 * @param self HTMLElement - Das Element, dass den Backdrop ausgelöst hat
 * @param exceptions string[] (optional) - Ausnahmen bei denen das Click-Event (inklusive Children) dennoch durchgehen soll (Klassen oder HTML-Tags als string)
 */
export function propagateClickEventThroughOverlayBackdrop({
  mouseEvent,
  self,
  exceptions = [],
}: {
  mouseEvent: MouseEvent;
  self: HTMLElement;
  exceptions?: string[];
}): void {
  const target = document.elementFromPoint(
    mouseEvent.clientX,
    mouseEvent.clientY
  ) as HTMLElement;

  if (!target) {
    return;
  }

  const isTargetSelf = self?.contains(target); // Checken ob target das eigene Element ist
  const exceptionElement = exceptions
    .map((exception) => target.closest(exception))
    .find((element) => element !== null) as HTMLElement | null; // Checken ob target ein Element ist, dass eine Ausnahme darstellt

  const isNotSelfOrIsException = !isTargetSelf || exceptionElement; // Click-Event soll durchgehen, wenn target außerhalb zu self liegt oder eine Ausnahme ist.
  const isTargetOrExceptionClickable =
    typeof target.click === 'function' ||
    typeof exceptionElement?.click === 'function'; // Checken ob target oder exceptionElement klickbar sind

  if (isNotSelfOrIsException && isTargetOrExceptionClickable) {
    if (exceptionElement) {
      exceptionElement.click();
    } else {
      target.click();
    }
  }
}
