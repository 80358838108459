import { CommonModule, WeekDay } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
  signal,
  ChangeDetectionStrategy,
  AfterViewInit,
  OnInit,
  input,
  effect,
  untracked,
} from '@angular/core';
import { DateAdapter } from '@paragondata/ngx-ui/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslocoModule } from '@jsverse/transloco';
import { CalendarBodyBase } from './calendar-body-base';
import { BehaviorSubject } from 'rxjs';
import { ParProgressModule } from '@paragondata/ngx-ui/progress';
import {
  ContributorInfoDto,
  ContributorScheduleDto,
  ScheduleItemInfoDto,
} from '@swagger/humanresources';
import { ParScrollContainerDirective } from '@shared/ui';
import {
  CalendarGroupBodyDayEntryComponent,
  CalendarGroupFullDayEntryComponent,
} from '../body-entry';
import { CalendarEntryTimeFilterPipe, CalendarTimeFilterPipe, DateService } from '@shared/utils';

import { timeIntervalsGroup, times } from '../calendar-times';
import { ResponsiveService } from '@core/services';
import {
  BreakTimeDayCategoriesComponent,
  BreakTimeDayDataComponent,
} from '@features/calendar/shared/containers/footer';
import { BreakTimeService } from '@shared/break-time';
import { SessionService } from '@core/auth';
import { CalendarEmployeeFilterPipe } from '@features/calendar/business';
import { CalendarEntry } from '@shared/plan-defs';
import { CalendarEntriesFilterService } from '@features/my-plan';

@Component({
  selector: 'calendar-group-body-day',
  templateUrl: 'calendar-group-body-day.component.html',
  styleUrls: ['calendar-group-body.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    CalendarGroupBodyDayEntryComponent,
    CalendarTimeFilterPipe,
    CalendarGroupFullDayEntryComponent,
    ParProgressModule,
    ParScrollContainerDirective,
    BreakTimeDayDataComponent,
    BreakTimeDayCategoriesComponent,
    CalendarEmployeeFilterPipe,
    CalendarEntryTimeFilterPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarGroupBodyDayComponent
  extends CalendarBodyBase
  implements OnChanges, AfterViewInit, OnInit
{
  calendarGroupBreakTimeDayService = inject(BreakTimeService);
  today = this.dateAdapter.today();
  weekDayNames = this.dateAdapter.getWeekdaysShort(WeekDay.Monday);
  weekDates: Date[];

  private destroy = inject(DestroyRef);
  @Input() loading: boolean = false;
  @Output() loadMoreSchedules: EventEmitter<void> = new EventEmitter<void>();
  @Input() schedules: ContributorScheduleDto[] = [];
  @Input() date: Date;
  @Input() entries: CalendarEntry[] = [];
  employeeList = input<ContributorInfoDto[]>([]);
  @Output() updateSchedules: EventEmitter<ContributorScheduleDto[]> =
    new EventEmitter<ContributorScheduleDto[]>();
  @ViewChild('scrollContainer', { static: true }) scrollContainer: ElementRef;

  fullDayEntry$ = new BehaviorSubject<boolean>(false);
  filteredEntries = signal([]);

  session = inject(SessionService);

  get times() {
    return times;
  }

  get timeIntervals() {
    return timeIntervalsGroup;
  }

  employeeListEffect = effect(() => {
    const employeeList = this.employeeList();
    untracked(() => {
      if (employeeList.length <= 20) {
        // Scroll To Top after Data Changed (e.g. Filter)
        this.scrollContainer.nativeElement.scrollTop = 0;
        this.cdr.markForCheck();
        this.cdr.detectChanges();
      }
    });
  });

  constructor(
    private dateAdapter: DateAdapter,
    private cdr: ChangeDetectorRef,
    public responsive: ResponsiveService,
    public dateService: DateService,
    public entriesFilterService: CalendarEntriesFilterService
  ) {
    super();
  }

  ngOnInit(): void {
    this.calendarGroupBreakTimeDayService.changeSchedules$
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((schedules) => {
        this.updateSchedules.emit(schedules);
      });
  }

  ngOnChanges({ entries, date, schedules }: SimpleChanges): void {
    if (schedules?.currentValue && schedules.currentValue.length > 0) {
      this.calendarGroupBreakTimeDayService.schedules$.next([
        ...schedules.currentValue,
      ]);
    }

    if (date?.currentValue) {
      this.calendarGroupBreakTimeDayService.selectedDate$.next(
        date.currentValue
      );
    }

    if (entries?.currentValue) {
      this.filteredEntries.set(
        this.entriesFilterService.transform(this.entries, 'day', this.date)
      );
    }

    this.cdr.markForCheck();
  }

  ngAfterViewInit(): void {
    this.scroll();
  }

  scroll() {
    let options: ScrollToOptions = {
      left: 16 * 4 * 8,
      behavior: 'instant',
    };
    if (this.scrollContainer?.nativeElement) {
      this.scrollContainer.nativeElement.scrollTo(options);
    }
  }

  triggerDataLoading(reachEnd: Event): void {
    this.loadMoreSchedules.emit();
  }

  onReachEnd(reachEnd: Event): void {
    this.triggerDataLoading(reachEnd);
  }

  async onAddOrUpdateBreaks({
    scheduleItemInfos = [],
    newValues,
  }: {
    scheduleItemInfos?: ScheduleItemInfoDto[];
    newValues?: { start: string; stop: string }; // Bei Add oder Update notwendig,
  }) {
    const response =
      await this.calendarGroupBreakTimeDayService.addOrUpdateBreaks({
        scheduleItemInfosToUpdate: scheduleItemInfos,
        newValues,
      });
    await this.calendarGroupBreakTimeDayService.updateGroupPlanViewAfterAddOrUpdateBreaks(
      response
    );
  }

  async onRemove(scheduleItemInfos?: ScheduleItemInfoDto[]) {
    const response = await this.calendarGroupBreakTimeDayService.removeBreaks(
      scheduleItemInfos
    );
    await this.calendarGroupBreakTimeDayService.updateGroupPlanViewAfterRemoveBreaks(
      response
    );
  }
}
