<ul class="page-account-sidebar__router-links-list">
  <a
    class="page-account-sidebar__account-settings"
    routerLinkActive="active"
    routerLink="./settings"
    >{{ "account-settings" | transloco }}</a
  >
  <a
    class="page-account-sidebar__account-information"
    routerLinkActive="active"
    routerLink="./information"
    >{{ "account-information" | transloco }}</a
  >
</ul>
