<ng-container *ngIf="overlappingCount; let count">
  <div
    class="my-1 p-2 h-12 bg-hrp-accent-100 text-hrp-accent-700 border border-solid border-hrp-accent-500 hrp-text-body-xs-emph rounded-[5px] flex flex-row items-center justify-between"
    *ngIf="count > 0"
    data-what="overlapping-notification-container"
  >
    <span class="material-symbols-rounded self-start mr-2"> info </span>
    <div data-what="overlapping-notification-text">
      <ng-container *ngIf="count === 1"
        >Es hat sich bereits {{ count }} Person
      </ng-container>
      <ng-container *ngIf="count > 1"
        >Es haben sich bereits {{ count }} Personen
      </ng-container>
      in diesem Zeitfenster eingetragen
    </div>
  </div>
</ng-container>
