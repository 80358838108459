<button
  class="hrp-multiselect__main-select-button"
  [multiselectOverlayTrigger]="optionsOverlay"
  (onToggle)="toggleMultiselect()"
  type="button"
>
  <div class="hrp-multiselect__content-container">
    @if ($value()?.length > 0) { @for(value of $value() | sort :
    $displayNameFn(); track value) {
    <hrp-multiselect-selected-option-tag tabindex="1" (onRemove)="onRemoveOptionTag(value)">
      {{ $displayNameFn()(value) }}
    </hrp-multiselect-selected-option-tag>
    } } @else {
    <span class="hrp-multiselect__placeholder">
      {{ $placeholder() }}
    </span>
    }
  </div>

  <div class="hrp-multiselect__main-select-icon">
    <ng-icon
      [name]="$toggled() ? 'matExpandLessOutline' : 'matExpandMoreOutline'"
    ></ng-icon>
  </div>
</button>

<ng-template #optionsOverlay>
  <div class="hrp-multiselect__overlay-container">
    <ng-content></ng-content>
  </div>
</ng-template>
