<div
  data-what="plan-main-container overflow-hidden"
  class="flex flex-col"
  [ngClass]="{
    'bg-hrp-subtle-100': responsive.$isMobile(),
    'bg-hrp-subtle-200 gap-3 h-[calc(100vh - 96px)]': !responsive.$isMobile()
  }"
>
  <calendar-stepper
    [mode]="mode()"
    [selectedDate]="date()"
    [displayRange]="['day', 'week', 'month']"
    [groupCalendar]="true"
    class="bg-hrp-subtle-100 overflow-hidden"
    (navigateDays)="navigateDays($event)"
    (modeChanged)="setMode($event, true)"
    (dateChanged)="setSelectedDate($event)"
    [ngClass]="{
    'px-6 pt-2 h-24 bg-hrp-subtle-200': !responsive.$isMobile(),
  }"
  >
    @if (!responsive.$isMobile()) {
    <button
      data-which="actions"
      data-what="filter-button"
      type="button"
      class="tertiary small w-auto overflow-hidden"
      (click)="filterOverlay.open()"
      [class.active]="filterActive()"
    >
      <div
        class="flex flex-row items-center tertiary small rounded-md"
        [class.active]="filterActive()"
      >
        <span class="material-symbols-rounded mr-1">filter_list</span>
        {{ "users.filter" | transloco }}
      </div>
    </button>
    }
  </calendar-stepper>

  <!-- Filter Feedback -->
  @if(responsive.$isDesktop()){
  <horizontal-scroll class="mx-auto w-[calc(100vw-3rem)] h-6" justify="start">
    <ng-container *ngIf="$filteredOrganizationalUnits(); let ous">
      @for(ou of ous; track ou.uId) {
      <chip size="small" [attr.data-which]="'ou-chip-' + ou.name">
        {{ ou.name }}
      </chip>
      }
    </ng-container>
  </horizontal-scroll>
  }

  <!-- Calendar Navigation for Mobile -->
  @if (responsive.$isMobile() && mode() === 'day') {
  <calendar-navigation-mobile-week
    [selectedDate]="date()"
    (navigateDays)="navigateDays($event)"
    (selectDay)="setSelectedDate($event)"
  ></calendar-navigation-mobile-week>
  }
  <!-- Dynamic Calendar Body -->
  @if (mode() === 'day') {
  <calendar-group-body-day
    [date]="date()"
    [entries]="flatEntries()"
    [loading]="loading()"
    [employeeList]="rows()"
    [schedules]="schedules()"
    (loadMoreSchedules)="loadMoreSchedules()"
    (updateSchedules)="updateSchedules($event)"
    [ngClass]="{
      'px-6': responsive.$isDesktop(),
    }"
  ></calendar-group-body-day>
  }@else if (mode() === 'week') {
  <calendar-group-body-week
    [date]="date()"
    [entries]="flatEntries()"
    [loading]="loading()"
    [employeeList]="rows()"
    (dateChanged)="setSelectedDate($event)"
    (modeChanged)="setMode($event, true)"
    (loadMoreSchedules)="loadMoreSchedules()"
    [ngClass]="{
      'px-6': responsive.$isDesktop(),
    }"
  ></calendar-group-body-week>
  }@else {
  <calendar-group-body-month
    [date]="date()"
    [entries]="flatEntries()"
    [loading]="loading()"
    [employeeList]="rows()"
    (dateChanged)="setSelectedDate($event)"
    (modeChanged)="setMode($event, true)"
    (loadMoreSchedules)="loadMoreSchedules()"
    [ngClass]="{
      'px-6': responsive.$isDesktop(),

    }"
  ></calendar-group-body-month>
  }
</div>

<!-- Mobile Footer -->
@if (responsive.$isMobile()) {
<div class="fixed w-full h-16 bottom-1 flex items-center px-4 z-[350]">
  <div
    data-which="mobile-actions"
    class="bg-hrp-subtle-300 w-full h-full flex justify-around items-center fixed-footer-widget shadow-dialog"
  >
    <button
      data-what="breaks-button"
      *ngIf="mode() !== 'month' && !isInfoRole"
      type="button"
      class="flex flex-col items-center w-[4.5rem] text-hrp-primary-700 hrp-text-body-xs-emph"
      (click)="
        calendarGroupBreakTimeDayService.openBreakTimeDialog({ mobile: true })
      "
    >
      <span class="material-symbols-rounded">local_cafe</span>
      Pausen
    </button>
    <button
      data-what="filter-button"
      type="button"
      class="flex flex-col items-center w-[4.5rem] text-hrp-primary-700 hrp-text-body-xs-emph"
      (click)="filterOverlay.open()"
    >
      <span class="material-symbols-rounded">filter_list</span>
      {{ "users.filter" | transloco }}
    </button>
  </div>
</div>
}

<!-- Filter Overlay -->
<hrp-filter-overlay #filterOverlay>
  <calendar-group-filter
    *ngIf="filterOverlay.isOpen"
    [filterSettings]="$filter()"
    (closeFilterOverlayEvent)="filterClosed($event); filterOverlay.close()"
  ></calendar-group-filter>
</hrp-filter-overlay>

<!-- Loading Spinner -->
<par-progress-spinner
  *ngIf="loading()"
  class="calendar-spinner"
  size="54"
  mode="indeterminate"
></par-progress-spinner>
