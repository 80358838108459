
import { Routes } from '@angular/router';
import { PageAccountComponent } from './account.component';
import { PageAccountSettingsComponent } from '../container/settings';
import { PageAccountInformationComponent } from '../container/information';
import { PageAccountResponsiveComponent } from '../container/responsive';

export const routes: Routes = [
  {
    path: '',
    component: PageAccountComponent,
    children: [
      { path: 'settings', component: PageAccountSettingsComponent },
      { path: 'information', component: PageAccountInformationComponent },
      { path: 'responsive', component: PageAccountResponsiveComponent}
    ],
  },
];
