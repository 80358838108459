<div
  parScrollContainer="both"
  (reachEnd)="onReachEnd()"
  [oldTop]="oldTop"
  class="calendar-group-week-container"
  #scrollContainer
>
  <div class="calendar-group-week-header">
    <div
      [class.invisible]="(responsive.breakpoint$ | async) !== 'desktop'"
      class="w-[12rem] flex items-center justify-center h-16"
    >
      <span class="hrp-text-body-xs text-[#607385]">{{
        "calendar-person" | transloco
      }}</span>
    </div>
    <div class="calendar-group-week-day">
      @for(weekDay of weekDayNames; let i = $index; track i){
      <button
        data-which="calendar-day"
        [attr.data-what]="weekDates[i]?.toISOString()"
        type="button"
        (click)="clickOnCalendarDay(weekDates[i])"
        class="col-span-1"
      >
        <div
          class="flex flex-col justify-center items-center gap-1 mx-4"
          [class.calendar-today]="dateService.isToday(weekDates[i])"
        >
          <div
            class="hrp-text-body-xs-bold"
            [style.color]="
              dateService.isToday(weekDates[i]) ? '#DBFFFD' : '#607385'
            "
          >
            {{ weekDay.replace(".", "") }}
          </div>
          <div class="flex flex-row items-baseline">
            <div
              class="desktop:hrp-text-body-20-bold tablet:hrp-text-body-small-bold mobile:hrp-text-body-small-bold"
              [style.color]="
                dateService.isToday(weekDates[i]) ? '#DBFFFD' : '#061A2D'
              "
            >
              {{ weekDates[i] | date : "d.MM" }}
            </div>
            <div
              *ngIf="(responsive.breakpoint$ | async) === 'desktop'"
              class="desktop:hrp-text-body tablet:hrp-text-body-small mobile:hrp-text-body-small"
              [style.color]="
                dateService.isToday(weekDates[i]) ? '#DBFFFD' : '#495969 '
              "
            >
              {{ weekDates[i] | date : ".yy" }}
            </div>
          </div>
        </div>
      </button>
      }
    </div>
  </div>

  <ng-container *ngIf="employeeList()?.length > 0; else emptyTemplate">
    <div
      class="calendar-group-week-employee-row"
      *ngFor="let employee of employeeList(); trackBy: trackByEmployees"
    >
      <div class="calendar-group-week-employee-data">
        <span class="hrp-text-body-small-bold">{{ employee?.lastName }} </span>
        <span class="hrp-text-body-small">{{ employee?.firstName }} </span>
      </div>
      <div
        [attr.data-which]="
          'calendar-week-data-for-' +
          employee?.firstName +
          '-' +
          employee?.lastName
        "
        class="calendar-group-week-content-container"
      >
        @for(weekDay of weekDayNames; let lastDay = $last; track weekDay){
        <div
          class="calendar-group-week-day-cell"
          [ngClass]="{ 'bg-hrp-subtle-100': lastDay }"
          [attr.data-what]="
            'calendar-day-' +
            weekDates[$index]?.toISOString() +
            '-data-for-' +
            employee?.firstName +
            '-' +
            employee?.lastName
          "
        >
          <ng-container *ngIf="filteredDayEntries$ | async; let entries">
            <ng-container
              *ngIf="
                entries[weekDay] | calendarEmployeeFilter : employee?.uId;
                let employeeEntries
              "
            >
              <ng-container
                *ngIf="
                  employeeEntries | calendarTimeIntervalFilter;
                  let entries
                "
              >
                <ng-container
                  *ngFor="
                    let entry of entries.slice(0, 3);
                    let i = index;
                    trackBy: trackByEntries
                  "
                >
                  <calendar-general-entry
                    [amount]="entries.length"
                    [entry]="entry"
                    [day]="weekDates[$index]"
                    [parentMode]="'group'"
                    [largeWidth]="
                      (responsive.breakpoint$ | async) === 'desktop'
                    "
                  ></calendar-general-entry>
                </ng-container>

                <span
                  *ngIf="entries.length > 3"
                  class="hrp-text-body-xs-emph h-2 w-full flex justify-center items-center"
                  >...</span
                >
              </ng-container>
            </ng-container>
          </ng-container>
        </div>
        }
      </div>
    </div>
  </ng-container>
</div>

<ng-template #emptyTemplate>
  <div
    class="flex flex-col w-auto h-[calc(100vh-222px)] bg-hrp-subtle-300 rounded-button px-12 py-12"
    *ngIf="!loading"
  >
    <p class="hrp-text-body-20-emph text-hrp-subtle-800">
      Keine Daten vorhanden!
    </p>
  </div>
</ng-template>
