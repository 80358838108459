@if ($loading()) {
<par-progress-spinner
  size="54"
  mode="indeterminate"
  class="justify-self-center"
></par-progress-spinner>
} @else {
<h1 class="page-account-settings__headline">
  {{ "account-settings" | transloco }}
</h1>
<div
  class="flex flex-col desktop:px-12 desktop:pt-12 tablet:px-12 tablet:pt-6 tablet:pb-12 mobile:px-2 mobile:pt-6 mobile:pb-12 gap-1"
>
  <label>{{ "account-language" | transloco }}</label>
  <par-select
    class="page-account-settings__language-select font-bold"
    [value]="$selectedLanguage()"
    (valueChange)="onLanguageChange($event)"
    [displayNameFn]="displayNameFn"
  >
    <par-select-option class="page-account-settings__option-german" value="de">
      <ng-container single>{{
        "account-german" | transloco
      }}</ng-container></par-select-option
    >
    <par-select-option class="page-account-settings__option-english" value="en"
      ><ng-container single>{{
        "account-english" | transloco
      }}</ng-container></par-select-option
    >
  </par-select>
</div>
}
