import { ChangeDetectionStrategy, Component } from '@angular/core';
import { PageAccountSettingsComponent } from '../settings';
import { PageAccountInformationComponent } from '../information';

@Component({
  selector: 'page-account-responsive',
  templateUrl: 'responsive.component.html',
  styleUrls: ['responsive.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [PageAccountSettingsComponent, PageAccountInformationComponent],
  standalone: true,
})
export class PageAccountResponsiveComponent {}
