import { CommonModule, Time } from '@angular/common';
import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  inject,
} from '@angular/core';
import { DateAdapter } from '@paragondata/ngx-ui/core';
import { ScheduleItemInfoDto } from '@swagger/humanresources';
import { SkeletonLoaderDirective } from 'src/shared/skeleton-loader/skeleton-loader.directive';
import {
  BreakTimeService,
  filterBreakItemsWithinTime,
  findBreakItemWhichLiesWithinTimespan,
  findNeighbouringBreakItems,
  getISODateByTimeInterval,
} from '@shared/break-time';
import moment from 'moment';

type BreakInfo = {
  takenBreaksCount: number;
  containsBreakForCurrentUser?: boolean;
};

@Component({
  selector: 'page-break-time-day-data',
  templateUrl: 'break-time-day-data.component.html',
  styleUrls: ['break-time-day-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SkeletonLoaderDirective],
})
export class BreakTimeDayDataComponent {
  @Input() allBreakItems: ScheduleItemInfoDto[];
  @Input() allBreakItemsForCurrentUser: ScheduleItemInfoDto[];
  @Input() selectedDate: Date;
  @Input() possibleBreaks: { start: number; stop: number }[];
  @Output() addOrUpdateBreak = new EventEmitter<{
    scheduleItemInfos?: ScheduleItemInfoDto[];
    newValues?: { start: string; stop: string };
  }>();
  @Output() removeBreak = new EventEmitter<ScheduleItemInfoDto[]>();

  breakTimeDayService = inject(BreakTimeService);
  dateAdapter = inject(DateAdapter);

  get timeIntervalsGroup() {
    return this.breakTimeDayService.timeIntervalsGroup;
  }

  getBreakInfosByTimeInterval(time: Time): BreakInfo {
    // 1. Filtern aller Pausen für den Tag nach Zeitspanne die über die Funktion reinkommt
    const allBreakItemsForTimespan = filterBreakItemsWithinTime({
      breakItems: this.allBreakItems,
      time,
    });

    // 2. Wenn in der gegebenen Zeitspanne keine Pause vorhanden ist, wird dieses default objekt returned
    if (allBreakItemsForTimespan?.length === 0) {
      return { takenBreaksCount: 0, containsBreakForCurrentUser: false };
    }

    // 3. Informationen für die gegebene Zeitspanne zurückgeben (z.B. 10 Uhr - 10:30 Uhr)
    return {
      takenBreaksCount: allBreakItemsForTimespan?.length ?? 0,
      containsBreakForCurrentUser: this._containsBreakForCurrentUser(
        allBreakItemsForTimespan
      ),
    };
  }

  isConributorPresent(time: Time): boolean {
    return this.possibleBreaks?.some((range) => {
      const timeInUnix = this._timeInUnixTimeStap(time);
      return timeInUnix >= range?.start && timeInUnix <= range?.stop;
    });
  }

  addOrRemoveSingleBreak({
    time,
    breakInfo,
  }: {
    time: Time;
    breakInfo?: BreakInfo;
  }) {
    const start = getISODateByTimeInterval({
      selectedDate: this.selectedDate,
      time,
    });
    const stop = getISODateByTimeInterval({
      selectedDate: this.selectedDate,
      time: { hours: time.hours, minutes: time.minutes + 30 },
    });

    if (!start || !stop) {
      return;
    }

    let existingBreakItemForCurrentUser:
      | ScheduleItemInfoDto
      | ScheduleItemInfoDto[] = undefined;
    // Wenn eine Pause für den aktuellen User existiert, kann diese nur gelöscht werden für das gewählten Zeitintervall
    const type = breakInfo?.containsBreakForCurrentUser ? 'remove' : 'add';
    if (type === 'add') {
      // Prüfen ob Pausen für den aktuellen User um den gegebenen Zeitspanne existiert
      existingBreakItemForCurrentUser = findNeighbouringBreakItems({
        breakItems: this.allBreakItemsForCurrentUser,
        start,
        stop,
      });
    } else {
      // Prüfen ob eine Pause für den aktuellen User in der gegebenen Zeitspanne existiert
      existingBreakItemForCurrentUser = findBreakItemWhichLiesWithinTimespan({
        breakItems: this.allBreakItemsForCurrentUser,
        start,
        stop,
      });
    }

    const value = this.breakTimeDayService.getBreakValuesForUpsertAndRemove({
      start,
      stop,
      existingBreakItemForCurrentUser,
      type,
    });

    value?.forEach((item) => {
      if (!item.hasOwnProperty('newValues')) {
        this.removeBreak.emit(item.scheduleItemInfos);
      } else {
        this.addOrUpdateBreak.emit(item);
      }
    });
  }

  // Prüfen ob Pausen für den aktuellen User vorhanden sind um in der UI entsprechende Stylings zu setzen
  private _containsBreakForCurrentUser(
    breaksToCheck: ScheduleItemInfoDto[]
  ): boolean {
    return !!this.allBreakItemsForCurrentUser?.find((item) =>
      breaksToCheck.find((breakItem) => breakItem.uId === item.uId)
    );
  }

  private _timeInUnixTimeStap(time: Time) {
    return new Date(this.selectedDate).setHours(time.hours, time.minutes);
  }
}
