<div
  parScrollContainer="both"
  [setOverflow]="false"
  (reachEnd)="onReachEnd($event)"
  class="relative calendar-group-day-container"
  [deltaEnd]="24"
  #scrollContainer
  id="calendar-group-day-container"
>
  <div class="calendar-group-day-employee-list">
    <div class="day-employee-list-header">
      <div
        [class.invisible]="(responsive.breakpoint$ | async) !== 'desktop'"
        class="flex items-center justify-center h-16 tablet:h-6 w-[12rem] mobile:h-6 mobile:w-0"
      >
        <span class="hrp-text-body-xs text-[#607385]">{{
          "calendar-person" | transloco
        }}</span>
      </div>
    </div>

    @for (row of employeeList(); let idx = $index; track row?.uId || idx) {
    <div class="day-employee-row">
      <div
        class="day-employee-data"
        [title]="row?.lastName + ' ' + row?.firstName"
      >
        <span
          class="desktop:hrp-text-body-small-bold tablet:hrp-text-body-small-bold mobile:hrp-text-body-xs-bold"
          >{{ row?.lastName || "" }}
        </span>
        <span
          class="desktop:hrp-text-body-small tablet:hrp-text-body-small mobile:hrp-text-body-xs"
          >{{ row?.firstName || "" }}
        </span>
      </div>
    </div>
    }

    <ng-container
      *ngIf="
        employeeList()?.length > 0 &&
        (responsive.breakpoint$ | async) !== 'mobile' &&
        !session.isInfo
      "
    >
      <page-break-time-day-categories
        (openDialog)="calendarGroupBreakTimeDayService.openBreakTimeDialog({})"
      ></page-break-time-day-categories>
    </ng-container>
    <div *ngIf="responsive.$isMobile()" class="spacer h-20 w-full"></div>
  </div>
  <div
    #calendar
    class="calendar-group-day-content-container"
    id="calendar-group-day-content-container"
  >
    <div class="calendar-group-day-header">
      <div
        class="w-full flex flex-col h-16 tablet:h-6 mobile:h-auto items-start justify-between pt-2"
      >
        <div class="flex hrp-text-body-20-bold h-auto w-auto">
          <div
            [class.invisible]="(responsive.breakpoint$ | async) !== 'desktop'"
            class="flex flex-row fixed rounded-button"
            [style.backgroundColor]="dateService.isToday(date) ? '#495969' : ''"
            [style.padding]="dateService.isToday(date) ? '4px' : ''"
          >
            <div
              [style.color]="dateService.isToday(date) ? '#DBFFFD' : '#607385'"
              class="mr-1"
            >
              {{ date | date : "EE " }}
            </div>
            <div
              [style.color]="dateService.isToday(date) ? '#DBFFFD' : '#061A2D'"
            >
              {{ date | date : "dd.MM" }}
            </div>
            <div
              class="hrp-text-body-20"
              [style.color]="dateService.isToday(date) ? '#DBFFFD' : '#495969 '"
            >
              {{ date | date : ".yy" }}
            </div>
          </div>
        </div>
        <div class="grid grid-cols-times justify-self-end h-auto">
          @for(time of times; let i = $index; track time){
          <div
            class="col-span-1 hrp-text-body-xs-bold text-[#495969] flex items-center justify-center"
          >
            {{ time }}
          </div>
          }
        </div>
      </div>
    </div>
    <div
      class="w-full grid grid-cols-time-intervals border-b-2 border-[#D3D9DF] bg-[#FFF]"
      *ngFor="let employee of employeeList(); trackBy: trackByEmployees"
      #row
      [attr.data-which]="
        'data-row-for-' + employee?.firstName + '-' + employee?.lastName
      "
    >
      @for(time of timeIntervals; let i = $index; track time){

      <div
        [attr.data-what]="'data-cell-at-' + time.hours + ':' + time.minutes"
        class="day-cell"
        #cell
        [ngClass]="{
          'border-[#FFF]': i % 4 === 1 || i % 4 === 3,
          'border-[#F3F4F6]': i % 4 === 0,
          'border-[#D3D9DF]': i % 4 === 2
        }"
      >
        @defer {
        <ng-container *ngIf="employee?.uId">
          <ng-container
            *ngIf="
              filteredEntries() | calendarEmployeeFilter : employee?.uId;
              let employeeEntries
            "
          >
            @for(employeeEntry of employeeEntries; track employeeEntry.id){
            @if((employeeEntry?.item?.timeInterval === 4 ||
            employeeEntry?.item?.scheduleItemType?.timeInterval === 4) &&
            time.hours === 0 && time.minutes === 0;){
            <calendar-group-full-day-entry
              [entry]="employeeEntry"
              [timeIntervals]="timeIntervals.length - 4"
              [cellWidth]="cell.getBoundingClientRect().width"
            >
            </calendar-group-full-day-entry>
            }@else if(employeeEntry?.item?.timeInterval === 1 ||
            employeeEntry?.item?.scheduleItemType?.timeInterval === 1){
            @if(employeeEntry | calendarEntryTimeFilter : time){

            <calendar-group-body-day-entry
              [entry]="employeeEntry"
              [cellWidth]="cell.getBoundingClientRect().width"
            ></calendar-group-body-day-entry>
            } } }
          </ng-container>
        </ng-container>
        } @placeholder {
        <div class="h-[64px] bg-[#fff]"></div>
        } @loading {
        <div class="h-[64px] bg-[#fff]"></div>
        }
      </div>
      }
    </div>
    <ng-container
      *ngIf="
        employeeList()?.length > 0 &&
        (responsive.breakpoint$ | async) !== 'mobile' &&
        !session.isInfo
      "
    >
      <page-break-time-day-data
        [selectedDate]="calendarGroupBreakTimeDayService.selectedDate$ | async"
        [allBreakItems]="
          calendarGroupBreakTimeDayService.allBreakItemsForDayViewFromScheduleFrame$
            | async
        "
        [allBreakItemsForCurrentUser]="
          calendarGroupBreakTimeDayService.getCurrentUserBreakItems$ | async
        "
        [possibleBreaks]="
          calendarGroupBreakTimeDayService.getPossibleBreaksForCurrentUserInUnixTimeArray$
            | async
        "
        (addOrUpdateBreak)="onAddOrUpdateBreaks($event)"
        (removeBreak)="onRemove($event)"
        data-what="page-break-time-day-data"
      ></page-break-time-day-data>
    </ng-container>
    <div *ngIf="responsive.$isMobile()" class="spacer h-20 w-full"></div>
  </div>
</div>
