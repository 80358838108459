import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChild,
  contentChildren,
  effect,
  HostListener,
  input,
  model,
  signal,
  untracked,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isEqual } from 'lodash';
import { OverlayModule } from '@angular/cdk/overlay';
import { MultiselectOverlayTriggerDirective } from './overlay-trigger';
import { HrpMultiselectSelectedOptionTagComponent } from './selected-option-tag';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { HrpMultiselectOptionComponent } from './multiselect-option.component';
import { ActiveDescendantKeyManager } from '@angular/cdk/a11y';
import { onKeydownFn } from './key-configuration';
import {
  matExpandLessOutline,
  matExpandMoreOutline,
} from '@ng-icons/material-icons/outline';
import { SortPipe } from '@shared/utils';
import { HrpMultiselectSearchbarComponent } from './searchbar';

export type DisplayNameFunction<T> = (obj: T) => string;

@Component({
  selector: 'hrp-multiselect',
  styleUrl: 'multiselect.component.scss',
  templateUrl: 'multiselect.component.html',
  imports: [
    OverlayModule,
    NgIconComponent,
    MultiselectOverlayTriggerDirective,
    HrpMultiselectSelectedOptionTagComponent,
    SortPipe,
  ],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'hrp-multiselect',
  },
  providers: [
    provideIcons({ matExpandMoreOutline, matExpandLessOutline }),
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: HrpMultiselectComponent,
      multi: true,
    },
  ],
})
export class HrpMultiselectComponent<T>
  implements ControlValueAccessor, AfterContentInit
{
  // Key Manager
  private readonly _$keyManager = computed(() => {
    const options = this.$options();
    const manager = new ActiveDescendantKeyManager<
      HrpMultiselectOptionComponent<T>
    >(options).withWrap();
    return manager;
  });

  // Content Child(ren)
  $search = contentChild(HrpMultiselectSearchbarComponent, {
    read: HrpMultiselectSearchbarComponent,
  });
  $options = contentChildren(HrpMultiselectOptionComponent<T>, {
    read: HrpMultiselectOptionComponent,
  });

  // Models
  $value = model<T[]>([], { alias: 'value' }); // Selected values
  $disabled = model<boolean>(false, { alias: 'disabled' });

  // Inputs
  $placeholder = input<string>('', { alias: 'placeholder' });
  $displayNameFn = input<DisplayNameFunction<T>>((obj) => String(obj), {
    alias: 'displayNameFn',
  });

  // Signals
  $toggled = signal<boolean>(false);

  constructor() {
    // Multiselect Options Effect - Registrierung & Selektion
    this._optionsValueHandlingEffect();

    // Suchbox Effects - Key Events & Query Clear
    this._handleSearchKeyEventEffect();
    this._clearSearchQueryOnCloseEffect();
  }

  ngAfterContentInit(): void {
    this.registerOptions();
  }

  onChange = (value: T[]) => {};
  onTouched = () => {};

  writeValue(obj: T[]): void {
    this.$value.set(obj);
  }

  registerOnChange(fn: any): void {
    this.onChange = (value) => {
      fn(value);
    };
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.$disabled.set(isDisabled);
  }

  // Wird nur von der MultiselectOverlayTriggerDirective aufgreufen und von dort zentral gesteuert
  toggleMultiselect() {
    this.$toggled.set(!this.$toggled());
    this.setDefaultFirstItemActive();
  }

  // Highlight first Item
  setDefaultFirstItemActive() {
    if (this.$toggled() && this.$options()?.length > 0) {
      this._$keyManager().setActiveItem(-1);
    }
  }

  registerOptions() {
    this.$options().forEach((option) => {
      this._registerOptionOnSelect(option);
      this._registerOptionOnRemove(option);
    });
  }

  setValue(value: T[]) {
    if (!isEqual(this.$value(), value)) {
      this.$value.set([...this.$value(), ...value]);
      this.onChange(this.$value());
      this.onTouched();
    }
  }

  removeOption(value: T) {
    const filteredValues = this.$value().filter((v) => !isEqual(v, value));
    this.$value.set(filteredValues);
    this.onChange(this.$value());
    this.onTouched();
  }

  // Hier muss zusätzlich die Option Checkbox unselektiert werden
  onRemoveOptionTag(value: T) {
    this.removeOption(value);
    this.$options()
      .find((o) => isEqual(o.$value(), value))
      .remove();
  }

  @HostListener('keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    if (this.$toggled()) {
      onKeydownFn({
        event,
        keyManager: this._$keyManager(),
      });
    }
  }

  private _registerOptionOnSelect(option: HrpMultiselectOptionComponent<T>) {
    option.registerOnSelect((value: T) => {
      if (!this.$value()?.includes(value)) {
        this.setValue([value]);
      }
    });
  }

  private _registerOptionOnRemove(option: HrpMultiselectOptionComponent<T>) {
    option.registerOnRemove((value: T) => {
      if (this.$value()?.includes(value)) {
        this.removeOption(value);
      }
    });
  }

  private _handleOptionSelectionBasedOnSelectedValues(selectedValues: T[]) {
    this.$options().forEach((o) => {
      if (selectedValues.includes(o.$value())) {
        o.select();
      } else {
        o.remove();
      }
    });
  }

  // effect notwendig wenn kein ngAfterContentInit ausgeführt wird um contentChildren ($options) zu registrieren
  // Dies ist der Fall, wenn man auf der gleichen View bleibt und die contentChildren ($options) die reingegeben werden gleich bleiben
  // Die ausgewählten Werte ($value) werden über writeValue trotzdem aktualisiert, sodass man anhand dessen die Options erneut selektieren muss
  private _optionsValueHandlingEffect() {
    effect(() => {
      const selectedValues = this.$value();
      this.$options();

      untracked(() => {
        this.registerOptions(); // Beim Einsatz der HrpMultiselectSearchbarComponent z.B., werden die Options jeweils neu gesetzt und müssen daher neu registriert werden
        this._handleOptionSelectionBasedOnSelectedValues(selectedValues);
      });
    });
  }

  // Wenn HrpMultiselectSearchbarComponent eingebunden ist, werden die KeyboardEvents hier mit dem zentralen KeyManager behandelt
  private _handleSearchKeyEventEffect() {
    effect(() => {
      const searchKeyEvent = this.$search()?.$keyDown();

      untracked(() => {
        if (searchKeyEvent) {
          this.handleKeydown(searchKeyEvent);
        }
      });
    });
  }

  private _clearSearchQueryOnCloseEffect() {
    effect(() => {
      const toggled = this.$toggled();

      untracked(() => {
        if (!toggled) {
          this.$search()?.$query?.set('');
        }
      });
    });
  }
}
