import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
  WritableSignal,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppStore } from '@app/store/app';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { ParProgressModule } from '@paragondata/ngx-ui/progress';
import { DisplayNameFunction, SelectModule } from '@paragondata/ngx-ui/select';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'page-account-settings',
  templateUrl: 'settings.component.html',
  styleUrls: ['settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    RouterModule,
    ParProgressModule,
    SelectModule,
    TranslocoModule,
  ],
  standalone: true,
})
export class PageAccountSettingsComponent {
  private _appStore = inject(AppStore);
  private _transloco = inject(TranslocoService);
  $loading: WritableSignal<boolean> = signal(false);

  get $selectedLanguage() {
    return this._appStore.$language;
  }

  get languageMap() {
    return { en: 'account-english', de: 'account-german' };
  }

  displayNameFn: DisplayNameFunction<string> = async (key: string) =>
    await firstValueFrom(
      this._transloco.selectTranslate<string>(this.languageMap[key])
    );

  constructor() {
    this._simulateLoadingAnimation();
  }

  onLanguageChange(language: string) {
    this._appStore.setLanguage(language);
  }

  private _simulateLoadingAnimation() {
    this.$loading.set(true);
    setTimeout(() => {
      this.$loading.set(false);
    }, 150);
  }
}
