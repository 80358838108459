import { Highlightable } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  ElementRef,
  HostListener,
  inject,
  input,
  model,
  signal,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'hrp-multiselect-option',
  styleUrl: 'multiselect-option.component.scss',
  templateUrl: 'multiselect-option.component.html',
  standalone: true,
  host: {
    '[class]': '["hrp-multiselect-option", activeClass(), selectedClass()]',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class HrpMultiselectOptionComponent<T> implements Highlightable {
  readonly _elementRef = inject(ElementRef<HTMLButtonElement>);
  $value = model.required<T>({ alias: 'value' }); // Option Value
  $disabled = input<boolean>(false, { alias: 'disabled' });

  private readonly _$active = signal(false); // Styling if Active (Hovered or Focused via Keyboard)
  $selected = signal(false); // Option Selected

  activeClass = computed(() => {
    return this._$active() === true
      ? `hrp-multiselect-option__focussed-option`
      : '';
  });

  selectedClass = computed(() => {
    return this.$selected() === true
      ? `hrp-multiselect-option__active-option`
      : '';
  });

  onSelect = (value: T) => {};
  onRemove = (value: T) => {};

  registerOnSelect(fn: any) {
    this.onSelect = (value) => {
      fn(value);
    };
  }

  registerOnRemove(fn: any) {
    this.onRemove = fn;
  }

  setActiveStyles(): void {
    this._$active.set(true);
  }

  setInactiveStyles(): void {
    this._$active.set(false);
  }

  scrollIntoView() {
    this._elementRef?.nativeElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });
  }

  @HostListener('click')
  onToggleOption(value?: T) {
    if (!this.$disabled()) {
      if (this.$selected()) {
        this.remove(value);
      } else {
        this.select(value);
      }
    }
  }

  select(value?: T) {
    this.onSelect(value ?? this.$value());
    this.$selected.set(true);
  }

  remove(value?: T) {
    this.onRemove(value ?? this.$value());
    this.$selected.set(false);
  }
}
