@if ($loading()) { @if ($isDesktop()) {
<par-progress-spinner
  size="54"
  mode="indeterminate"
  class="justify-self-center"
></par-progress-spinner>
} } @else {
<h1 class="page-account-information__headline">
  {{ "account-information" | transloco }}
</h1>
<div
  *ngIf="userData$ | async; let userData"
  class="w-full flex flex-col gap-6 desktop:pt-12 mobile:pt-6 tablet:pt-6 desktop:px-12 tablet:px-12 mobile:px-2"
>
  <div class="page-account-information__username flex flex-col gap-2">
    <p>Benutzername</p>
    <p class="hrp-text-body-bold">{{ userData.name }}</p>
  </div>
  <div
    class="page-account-information__roles flex flex-col gap-2"
    *ngFor="let role of userData.roles"
  >
    <p>Rolle</p>
    <p class="hrp-text-body-bold">{{ role.displayLabel }}</p>
  </div>
  <div class="page-account-information__ous flex flex-col gap-2">
    <p>Verfügbare Organisationseinheiten</p>
    <p *ngFor="let ou of userData?.ou" class="hrp-text-body-bold">
      {{ ou?.displayLabel }}
    </p>
  </div>
  <div class="flex flex-col gap-2">
    <p>Access Token</p>

    <div class="flex desktop:flex-row tablet:flex-row mobile:flex-col gap-2">
      <div class="flex flex-col">
        @if ($accessTokenExpiresAt()) {
        <p class="page-account-information__token-valid hrp-text-body-bold">
          Das Access Token ist bis zum
          {{ $accessTokenExpiresAt() | date : "medium" }} Uhr gültig.
        </p>
        }
        <p class="page-account-information__token-expires hrp-text-body-bold">
          Das Access Token läuft {{ $accessTokenExpiresIn() }} ab.
        </p>
      </div>
      <button
        type="button"
        (click)="refreshAccessToken()"
        class="page-account-information__token-refresh h-full desktop:self-center tablet:self-center mobile:self-end inline-block tertiary small desktop:ml-20 tablet:ml-20"
      >
        Token jetzt erneuern
      </button>
    </div>
  </div>
  <div class="page-account-information__app-version flex flex-col gap-2">
    <p>App-Version</p>
    <p class="hrp-text-body-bold">{{ appVersion }}</p>
  </div>
</div>
}
