import {
  ChangeDetectionStrategy,
  Component,
  input,
  ViewEncapsulation,
  signal,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { matCloseOutline } from '@ng-icons/material-icons/outline';

@Component({
  selector: 'hrp-multiselect-searchbar',
  templateUrl: 'multiselect-searchbar.component.html',
  styleUrl: 'multiselect-searchbar.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'hrp-multiselect-searchbar',
  },
  imports: [TranslocoModule, FormsModule, NgIconComponent],
  providers: [provideIcons({ matCloseOutline })],
})
export class HrpMultiselectSearchbarComponent {
  $disabled = input<boolean>(false, { alias: 'disabled' });
  $query = signal<string>('');
  $keyDown = signal<KeyboardEvent>(null);
}
