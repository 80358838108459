import {
  ChangeDetectionStrategy,
  Component,
  effect,
  inject,
  untracked,
} from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { ResponsiveService } from '@core/services';
import { PageAccountSidebarComponent } from './sidebar/sidebar.component';

@Component({
  selector: 'page-account',
  templateUrl: 'account.component.html',
  styleUrls: ['account.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [RouterOutlet, PageAccountSidebarComponent],
  standalone: true,
})
export class PageAccountComponent {
  private _responsive = inject(ResponsiveService);
  private _router = inject(Router);
  private _activatedRoute = inject(ActivatedRoute);

  get $isDesktop() {
    return this._responsive.$isDesktop;
  }

  constructor() {
    effect(() => {
      const breakpoint = this._responsive.$breakpoint();
      untracked(() => {
        switch (breakpoint) {
          case 'desktop':
            this._router.navigate(['./settings'], {
              replaceUrl: true,
              relativeTo: this._activatedRoute,
            });
            break;
          case 'mobile':
          case 'tablet':
            this._router.navigate(['./responsive'], {
              replaceUrl: true,
              relativeTo: this._activatedRoute,
            });
            break;
        }
      });
    });
  }
}
