<div
  class="navbar h-full"
  [class.mobile]="!responsive.$isDesktop()"
  [navFlyover]="flyover"
  #flyoverdirective="navFlyover"
>
  <div class="nav-container" data-what="nav-container">
    @for (item of navItems; track $index) {
    <navbar-item
      [item]="item"
      *ngIf="
        item.position === 'left' &&
        item.onNavbarForBreakpoint?.includes(responsive.$breakpoint()) &&
        $visible()[item.key]
      "
      (click)="handleClick(item, $event)"
      [activeItem]="activeItem$ | async"
      [attr.data-which]="'navbar-item-' + item?.key"
    >
      <ng-container *ngIf="item.children">
        <div
          class="w-full h-auto flex flex-col absolute mt-12 rounded-button bg-subtle-100 py-2 px-2 gap-1"
          data-what="child-items-container"
          >
          <ng-container *ngFor="let childItem of item.children; let i = index">
            <navbar-item-child
              [item]="childItem"
              [i]="i"
              (click)="handleClick(childItem, $event)"
              *ngIf="
                childItem.onNavbarForBreakpoint?.includes(
                  responsive.$breakpoint()
                ) && $visible()[childItem.key]
              "
              [attr.data-which]="'navbar-child-item-' + childItem?.key"
            ></navbar-item-child>
          </ng-container>
        </div>
      </ng-container>
    </navbar-item>
    }
  </div>
  <div class="nav-container" data-what="nav-container">
    @for (item of navItems; track $index) {
    <navbar-item
      [item]="item"
      [activeItem]="activeItem$ | async"
      *ngIf="
        item.position === 'right' &&
        item?.onNavbarForBreakpoint?.includes(responsive.$breakpoint()) &&
        $visible()[item.key]
      "
      (click)="handleClick(item, $event)"
      [attr.data-which]="'navbar-item-' + item?.key"
    >
      <ng-container *ngIf="item?.children">
        <div
          class="w-full h-auto flex flex-col absolute mt-12 rounded-button bg-subtle-100 py-2 px-2 gap-1"
        >
          <ng-container *ngFor="let childItem of item.children; let i = index">
            <navbar-item-child
              [item]="childItem"
              [i]="i"
              (click)="handleClick(childItem, $event)"
              *ngIf="
                childItem?.onNavbarForBreakpoint?.includes(
                  responsive.$breakpoint()
                ) && $visible()[childItem.key]
              "
            ></navbar-item-child>
          </ng-container>
        </div>
      </ng-container>
    </navbar-item>
    }
  </div>

  <ng-template #flyover>
    <div
      class="bg-base text-base-content z-20 grid grid-flow-row gap-3 w-full p-6"
    >
      <button
        (click)="flyoverdirective.toggle()"
        class="place-self-end"
        data-what="close-button"
      >
        <span class="material-symbols-rounded text-5xl text-subtle-300"
          >close</span
        >
      </button>
      @for(item of navItems; track $index) { @if(item?.children) {
      @for(childItem of item?.children; track $index) {
      <navbar-item-burger
        [item]="childItem"
        (click)="flyoverdirective.toggle(); handleClick(childItem, $event)"
        *ngIf="
          !childItem.onNavbarForBreakpoint?.includes(responsive.$breakpoint()) &&
          $visible()[childItem.key]
        "
        [attr.data-which]="'navbar-child-item-burger-' + childItem?.key"
      ></navbar-item-burger>

      } }@else {
      <navbar-item-burger
        [item]="item"
        (click)="flyoverdirective.toggle(); handleClick(item, $event)"
        *ngIf="
          !item.onNavbarForBreakpoint?.includes(responsive.$breakpoint()) &&
          $visible()[item.key] &&
          !(responsive.$breakpoint() === 'mobile' && item.hiddenMobile) &&
          !(responsive.$breakpoint() === 'tablet' && item.hiddenTablet)
        "
        [attr.data-which]="'navbar-item-burger-' + item?.key"
      ></navbar-item-burger>
      } }
    </div>
  </ng-template>
</div>
