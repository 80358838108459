import { Time } from '@angular/common';
import { getTime, greaterEqualTime, lessTime } from '@shared/utils';
import { ScheduleItemDto, ScheduleItemInfoDto } from '@swagger/humanresources';
import moment from 'moment';
import { CalendarEntry } from './types';

export function filterBreakItemByTypeAndIntervalAndDay(
  scheduleItemInfo: ScheduleItemInfoDto,
  day: Date
) {
  return (
    (scheduleItemInfo?.scheduleItemType?.key === 'BREAK' ||
      (scheduleItemInfo as ScheduleItemDto)?.scheduleItemType?.data?.key ===
        'BREAK') && // Fallback Notwendig da bei Hinzufügen oder Updaten von Pausen ein ScheduleItemDto zurückkommt
    scheduleItemInfo?.timeInterval === 1 &&
    day.toLocaleDateString() ===
      new Date(scheduleItemInfo.start).toLocaleDateString()
  );
}

export function filterAllBreakItems(scheduleItemInfo: ScheduleItemInfoDto) {
  return (
    scheduleItemInfo?.scheduleItemType?.key === 'BREAK' &&
    (scheduleItemInfo?.timeInterval === 1 ||
      scheduleItemInfo?.timeInterval === 2 ||
      scheduleItemInfo?.scheduleItemType?.timeInterval === 1 ||
      scheduleItemInfo?.scheduleItemType?.timeInterval === 2)
  );
}

export function filterAllBreakItemsDuration(
  scheduleItemInfo: ScheduleItemInfoDto
) {
  return (
    scheduleItemInfo?.scheduleItemType?.key === 'BREAK' &&
    scheduleItemInfo?.timeInterval === 2
  );
  //|| scheduleItemInfo?.scheduleItemType?.timeInterval === 2, müsste mit rein, wird aber aktuell im backend falsch gesetzt was hier zu einem falschen Ergebnis führt
}

export function filterAllBreakItemsTimespan(
  scheduleItemInfo: ScheduleItemInfoDto
) {
  return (
    scheduleItemInfo?.scheduleItemType?.key === 'BREAK' &&
    (scheduleItemInfo?.timeInterval === 1 ||
      scheduleItemInfo?.scheduleItemType?.timeInterval === 1)
  );
}

export function findBreakItemWhichLiesWithinTimespan({
  breakItems,
  start,
  stop,
}: {
  breakItems: ScheduleItemInfoDto[];
  start: string;
  stop: string;
}) {
  return breakItems.find((item) => {
    const itemStart = new Date(item?.start);
    const itemStop = new Date(item?.stop);
    if (
      !(itemStart.getTime() > new Date(stop).getTime()) &&
      itemStop.getTime() > new Date(start).getTime()
    ) {
      return item;
    }
  });
}
export function findNeighbouringBreakItems({
  breakItems,
  start,
  stop,
}: {
  breakItems: ScheduleItemInfoDto[];
  start: string;
  stop: string;
}) {
  return breakItems.filter((item) => {
    const itemStart = new Date(item?.start);
    const itemStop = new Date(item?.stop);
    return (
      (itemStart.getTime() === new Date(stop).getTime() ||
        itemStop.getTime() === new Date(start).getTime()) &&
      item
    );
  });
}

export function getBreakItemWithTimeInterval2(
  scheduleItemInfoDtos: ScheduleItemInfoDto[]
): ScheduleItemInfoDto {
  return scheduleItemInfoDtos.find((item) => {
    if (item.scheduleItemType?.key === 'BREAK') {
      return (
        item.timeInterval === 2 && item.scheduleItemType?.timeInterval === 2
      );
    }
  });
}

export function filterBreakItemsWithinTime({
  breakItems,
  time,
}: {
  breakItems: ScheduleItemInfoDto[];
  time: Time;
}): ScheduleItemInfoDto[] {
  return breakItems?.filter((item) => {
    const start = getTime(new Date(item.start));
    const stop = getTime(new Date(item.stop));

    if (!checkForMinimumIntervalTime({ start, stop, fraction: 5 })) {
      return false;
    }

    // #2179 Änderung der Implementierung, sodass auch Intervalle zwischen den Halben Stunden berücksichtigt werden
    // -> z.B. Pause 14:00 - 14:30 würde bei Time 14:00 angezeigt werden
    // NEU: -> z.B. Pause 14:10 - 15:45 würde bei Time 14:00, 14:30, 15:00 und 15:30 angezeigt werden
    const intervalStart = time;
    const intervalEnd = addMinutesToTime(time, 30);
    return (
      lessTime(start, intervalEnd) &&
      greaterEqualTime(stop, intervalStart) &&
      !equalTime(stop, intervalStart)
    );
  });
}

export function filterBreakItemsByTimespan({
  breakItems,
  start,
  stop,
}: {
  breakItems: ScheduleItemInfoDto[];
  start: Time;
  stop: Time;
}): ScheduleItemInfoDto[] {
  return breakItems?.filter((breakItem) => {
    const startItem = getTime(new Date(breakItem.start));
    const stopItem = getTime(new Date(breakItem.stop));
    return !(greaterEqualTime(start, startItem) || lessTime(stop, stopItem));
  });
}

export function getISODateByTimeInterval({
  selectedDate,
  time,
}: {
  selectedDate: Date;
  time: Time;
}): string {
  const date = new Date(selectedDate); // from selectedDate to get correct Day, Month and Year
  date?.setHours(time.hours, time.minutes, 0, 0);
  return date?.toISOString();
}

function addMinutesToTime(time: Time, minutes: number): Time {
  let newHours = time.hours;
  let newMinutes = time.minutes + minutes;

  if (newMinutes >= 60) {
    newHours += Math.floor(newMinutes / 60);
    newMinutes %= 60;
  }

  return { hours: newHours, minutes: newMinutes };
}

function equalTime(first: Time, second: Time): boolean {
  return first?.hours === second?.hours && first?.minutes === second?.minutes;
}

function checkForMinimumIntervalTime({
  start,
  stop,
  fraction,
}: {
  start: Time;
  stop: Time;
  fraction: number;
}): boolean {
  const startInMinutes = start.hours * 60 + start.minutes;
  const stopInMinutes = stop.hours * 60 + stop.minutes;
  return stopInMinutes - startInMinutes >= 30 / fraction;
}

export function isBreakAtTheEnd(
  existingBreakItemForCurrentUser: ScheduleItemInfoDto,
  stop: string
) {
  const currentUserStop = moment(
    existingBreakItemForCurrentUser.stop
  ).valueOf();
  const stopBegin = moment(stop).subtract(30, 'm').valueOf();
  const stopEnd = moment(stop).valueOf();
  return currentUserStop > stopBegin && currentUserStop <= stopEnd;
}

export function isBreakAtStart(
  existingBreakItemForCurrentUser: ScheduleItemInfoDto,
  start: string
) {
  const startBegin = moment(start).valueOf();
  const startEnd = moment(start).add(30, 'm').valueOf();
  const currentUserStart = moment(
    existingBreakItemForCurrentUser.start
  ).valueOf();
  return currentUserStart >= startBegin && currentUserStart < startEnd;
}

export function isExistingBreakEqualOrInBetween({
  existingBreakItemForCurrentUser,
  start,
  stop,
}: {
  existingBreakItemForCurrentUser: ScheduleItemInfoDto;
  start: string;
  stop: string;
}) {
  const currentUserStart = moment(existingBreakItemForCurrentUser.start);
  const currentUserStop = moment(existingBreakItemForCurrentUser.stop);
  const startMoment = moment(start);
  const stopMoment = moment(stop);

  return (
    (currentUserStart.isSame(startMoment) ||
      currentUserStart.isAfter(startMoment)) &&
    (currentUserStop.isSame(stopMoment) || currentUserStop.isBefore(stopMoment))
  );
}

export function isNewBreakInBetweenExsistingBreakes({
  existingBreakItemForCurrentUser,
  start,
  stop,
}: {
  existingBreakItemForCurrentUser: ScheduleItemInfoDto[];
  start: string;
  stop: string;
}) {
  if (existingBreakItemForCurrentUser.length !== 2) {
    return false;
  }

  const startDate = moment(start);
  const stopDate = moment(stop);

  const breakWithErliestStart = existingBreakItemForCurrentUser.find((item) =>
    moment(item.start).isBefore(startDate)
  );
  const breakWithLastestStop = existingBreakItemForCurrentUser.find((item) =>
    moment(item.stop).isAfter(stopDate)
  );

  if (
    moment(breakWithErliestStart.stop).isSame(startDate) &&
    moment(breakWithLastestStop.start).isSame(stopDate)
  ) {
    return true;
  }

  return false;
}

export function getPossibleBreakTimesForEmployee(allEntries: CalendarEntry[]) {
  const possibleBreaks: number[] = [];
  allEntries.forEach((entry) => {
    if (entry.item?.scheduleItemType?.presence === true) {
      const start = entry.start;
      const stop = entry.end;

      while (start.getTime() < stop.getTime()) {
        possibleBreaks.push(start.getTime());
        start.setMinutes(start.getMinutes() + 30);
      }
    }
  });

  const start: string[] = [];
  const stop: string[] = [];

  possibleBreaks.forEach((unixTime, index) => {
    const newDate = new Date(unixTime);
    const time = newDate.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });

    if (index === 0) {
      start.push(time);
    } else {
      start.push(time);
      stop.push(time);
    }

    if (index === possibleBreaks.length - 1) {
      //push the last time to the stop array
      const latestStopTime = new Date(unixTime).setMinutes(
        newDate.getMinutes() + 30
      );
      stop.push(
        new Date(latestStopTime).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })
      );
    }
  });

  return { start: start, stop: stop };
}
