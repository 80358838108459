import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ProcessComponent } from './process/process.component';
import { SessionService } from '@core/auth';
import { ResponsiveService } from '@core/services';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-process-bar',
    templateUrl: 'process-bar.component.html',
    styleUrl: 'process-bar.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ProcessComponent, NgIf]
})

export class ProcessBarComponent implements OnInit {

    processes: Array<{ id: number, name: string, active?: boolean }> = [
        { id: 1, name: 'Tab 1', active: true },
        // { id: 2, name: 'Tab 2', active: false },
        // { id: 3, name: 'Tab 3', active: false },
    ]


    constructor(private readonly _session: SessionService, public readonly responsive: ResponsiveService) { }

    ngOnInit() { }

    logout() {
        this._session.logout();
      }
}