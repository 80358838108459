import {
  Directive,
  ElementRef,
  TemplateRef,
  HostListener,
  input,
  signal,
  inject,
  DestroyRef,
  ViewContainerRef,
  output,
  AfterViewInit,
} from '@angular/core';
import {
  FlexibleConnectedPositionStrategy,
  Overlay,
  OverlayRef,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { propagateClickEventThroughOverlayBackdrop } from './propagate-click-event.helper';

@Directive({
  selector: '[multiselectOverlayTrigger]',
  standalone: true,
})
export class MultiselectOverlayTriggerDirective implements AfterViewInit {
  private readonly _overlay = inject(Overlay);
  private readonly _elRef = inject(ElementRef);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _viewContainerRef = inject(ViewContainerRef);
  private _$overlayRef = signal<OverlayRef | null>(null);
  $multiselectOverlayTrigger = input.required<TemplateRef<any>>({
    alias: 'multiselectOverlayTrigger',
  });
  $onToggle = output({ alias: 'onToggle' });

  // Wenn die Breite/Höhe der Select Box dynamisch verändert wird, wird das Overlay neu positioniert (z.B. bei Änderung der Höhe)
  ngAfterViewInit(): void {
    const resizeObserver = new ResizeObserver(() => {
      if (this._$overlayRef()) {
        this._updatePositionAndSize();
      }
    });
    resizeObserver.observe(this._elRef?.nativeElement);
  }

  @HostListener('click')
  toggleOverlay(): void {
    if (this._$overlayRef()) {
      this._closeOverlay();
    } else {
      this._openOverlay();
    }
  }

  @HostListener('keydown', ['$event'])
  handleKeydown(event: KeyboardEvent) {
    if (event.key === 'Space' || event.key === 'Escape') {
      this.toggleOverlay();
      event.stopPropagation();
      event.preventDefault();
    }

    if (event.key === 'Tab') {
      if (this._$overlayRef()) {
        this._closeOverlay();
      }
    }
  }

  private _openOverlay(): void {
    const overlay = this._createOverlay();
    this._attachPortalToOverlay(overlay);
    this._setupBackdropClose(overlay);
    this._$overlayRef.set(overlay);
    this.$onToggle.emit();
  }

  private _closeOverlay(): void {
    this._$overlayRef()?.dispose();
    this._$overlayRef.set(null);
    this.$onToggle.emit();
  }

  private _createOverlay(): OverlayRef {
    const positionStrategy = this._createPositionStrategy();
    return this._overlay.create({
      positionStrategy,
      hasBackdrop: true,
      backdropClass: 'cdk-overlay-transparent-backdrop',
      width: this._elRef?.nativeElement.offsetWidth + 2,
    });
  }

  private _attachPortalToOverlay(overlay: OverlayRef): void {
    const portal = new TemplatePortal(
      this.$multiselectOverlayTrigger(),
      this._viewContainerRef
    );
    overlay.attach(portal);
  }

  private _setupBackdropClose(overlay: OverlayRef): void {
    overlay
      .backdropClick()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((mouseEvent: MouseEvent) => {
        this._closeOverlay();
        propagateClickEventThroughOverlayBackdrop({
          mouseEvent,
          self: this._elRef?.nativeElement,
          exceptions: ['.hrp-multiselect-selected-option-tag__close-button'],
        });
      });
  }

  private _createPositionStrategy(): FlexibleConnectedPositionStrategy {
    return this._overlay
      .position()
      .flexibleConnectedTo(this._elRef)
      .withViewportMargin(8)
      .withDefaultOffsetY(1)
      .withDefaultOffsetX(-1)
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'bottom',
        },
        {
          originX: 'end',
          originY: 'center',
          overlayX: 'start',
          overlayY: 'center',
        },
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]);
  }

  private _updatePositionAndSize() {
    this._$overlayRef().updatePosition();
    this._$overlayRef().updateSize({
      width: this._elRef?.nativeElement.offsetWidth + 2,
    });
  }
}
