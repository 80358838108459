import { ChangeDetectionStrategy, Component, output, ViewEncapsulation } from '@angular/core';
import { NgIconComponent, provideIcons } from '@ng-icons/core';
import { matCloseRound } from '@ng-icons/material-icons/round';

@Component({
  selector: 'hrp-multiselect-selected-option-tag',
  templateUrl: 'multiselect-selected-option-tag.component.html',
  styleUrl: 'multiselect-selected-option-tag.component.scss',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIconComponent],
  providers: [provideIcons({ matCloseRound })],
  host: {
    class: 'hrp-multiselect-selected-option-tag',
    '[attr.tabindex]': '-1'
  },
  encapsulation: ViewEncapsulation.None,
})
export class HrpMultiselectSelectedOptionTagComponent {
  $onRemove = output<void>({ alias: 'onRemove' });
}
