import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { map } from "rxjs/operators";
import { appLanguages, appThemes, defaultLanguage, defaultTheme, IAppState } from "./app.state";
import { setLanguage, setState, setTheme } from "./app.actions";
import { selectLanguage, selectTheme } from "./app.selectors";
import { IState } from "../store.module";
import { toSignal } from "@angular/core/rxjs-interop";

@Injectable({providedIn: 'root'})
export class AppStore {
  languages = appLanguages;
  themes = appThemes;

  language$ = this.store.select(selectLanguage).pipe(map(language => language || defaultLanguage));
  $language = toSignal(this.language$);
  theme$ = this.store.select(selectTheme).pipe(map(theme => theme || defaultTheme));

  setTheme(theme?: string) {
    this.store.dispatch(setTheme({theme: theme || defaultTheme}));
  }

  setLanguage(language?: string) {
    this.store.dispatch(setLanguage({language: language || defaultLanguage}))
  }

  setState(state: IState) {
    this.store.dispatch(setState({state: state.app}))
  }

  constructor(private store: Store<IAppState>) {}
}