import { Time } from '@angular/common';
import { inject, Injectable } from '@angular/core';
import { SessionService } from '@core/auth';
import { TranslocoService } from '@jsverse/transloco';
import { DialogConfig, ParModalService } from '@paragondata/ngx-ui/modal';
import {
  BatchResponseArgsOfScheduleItemDtoAndBreakValues,
  BatchResponseArgsOfScheduleItemDtoAndScheduleItemDto,
  BreakValues,
  ContributorScheduleDto,
  ScheduleItemInfoDto,
  ScheduleItemTypeInfoDto,
} from '@swagger/humanresources';
import {
  BehaviorSubject,
  filter,
  firstValueFrom,
  map,
  Observable,
  withLatestFrom,
} from 'rxjs';
import {
  mapScheduleItemDtoToScheduleItemInfoDto,
  mapScheduleItemInfoDtoToBreakValues,
} from './break-time.mappings';
import {
  filterBreakItemByTypeAndIntervalAndDay,
  filterBreakItemsByTimespan,
  getBreakItemWithTimeInterval2,
  getISODateByTimeInterval,
  isBreakAtStart,
  isBreakAtTheEnd,
  isExistingBreakEqualOrInBetween,
  isNewBreakInBetweenExsistingBreakes,
} from './break-time.utils';

import { mapStringToTime } from '@shared/utils';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  BreakTimesDialogComponent,
  BreakTimesDialogData,
} from './break-times-dialog';
import { ContributorScheduleBusinessService } from './business';
import { timeIntervalsGroup } from './types';
import { OverlayPositionBuilder } from '@angular/cdk/overlay';
import moment from 'moment';

@Injectable({ providedIn: 'root' })
export class BreakTimeService {
  private _session = inject(SessionService);
  private _contributorScheduleService = inject(
    ContributorScheduleBusinessService
  );
  private _transloco = inject(TranslocoService);
  private _modal = inject(ParModalService);
  private _overlayPositionBuilder = inject(OverlayPositionBuilder);

  // --- !WICHTIG!
  // Diese Daten werden nur innerhalb der CalendarGroupBodyDayComponent (calendar-group-body-day.component.ts) innerhalb ngOnChanges bereitgestellt
  // Hier ist ein weiteres Refactoring notwendig, wenn diese Daten auch in anderen Komponenten bereitgestellt werden um z.B. gewisse Oberflächen upzudaten
  // Man bräuchte dann eine Art Feature Store der ein Interface enthält für die 3 BehaviorSubjects, welcher dann in den jeweiligen Features eingesetzt wird
  // Zur Info: Dieser Code ist während des Refactorings des Planungs + Gruppenplan Stores entstanden und wird aus Zeitgründen erstmal so belassen
  selectedDate$ = new BehaviorSubject<Date>(new Date());
  changeSchedules$ = new BehaviorSubject<ContributorScheduleDto[]>([]);
  schedules$ = new BehaviorSubject<ContributorScheduleDto[]>(
    new Array<ContributorScheduleDto>()
  );

  // --- !WICHTIG!

  selectDate = toSignal(this.selectedDate$);

  private _currentUser$ = this._session.user$;
  private _getEmployeeScheduleForCurrentUser$ = this.schedules$.pipe(
    withLatestFrom(this._currentUser$, this.schedules$),
    filter(
      ([schedulesFrame, currentUser]) =>
        !!schedulesFrame && !!schedulesFrame?.find((_) => true) && !!currentUser
    ),
    map(([schedulesFrame, currentUser]) =>
      schedulesFrame?.find(
        (schedulesFrame) => schedulesFrame?.contributor?.uId === currentUser.uId
      )
    )
  );

  private _getCurrentUserScheduleItems$ =
    this._getEmployeeScheduleForCurrentUser$.pipe(
      map(
        (employeeSchedule) =>
          employeeSchedule?.schedules?.flatMap((schedule) => schedule?.items) ??
          []
      )
    );

  getCurrentUserEmployeeUId$ = this._getEmployeeScheduleForCurrentUser$.pipe(
    map((employeeSchedule) => {
      return employeeSchedule?.contributor?.uId;
    })
  );

  getCurrentUserScheduleUId$ = this._getEmployeeScheduleForCurrentUser$.pipe(
    map(
      (employeeSchedule) =>
        employeeSchedule?.schedules?.find(
          (schedule) => schedule?.schedule?.enabled
        )?.schedule?.uId
    )
  );

  getCurrentUserBreakItems$ = this._getCurrentUserScheduleItems$.pipe(
    map((scheduleItemInfo) =>
      scheduleItemInfo.filter((item) =>
        filterBreakItemByTypeAndIntervalAndDay(item, this.selectDate())
      )
    )
  );

  allBreakItemsForDayViewFromScheduleFrame$ = this.schedules$.pipe(
    filter(
      (schedulesFrame) =>
        !!schedulesFrame && !!schedulesFrame?.find((_) => true)
    ),
    map((schedulesFrame) => {
      const schedules =
        schedulesFrame?.flatMap(
          (schedulesFrame) => schedulesFrame?.schedules
        ) ?? [];
      const itemsOfSchedules =
        schedules?.flatMap((schedule) => schedule?.items) ?? [];
      const breakItems =
        itemsOfSchedules?.filter((item) =>
          filterBreakItemByTypeAndIntervalAndDay(item, this.selectDate())
        ) ?? [];

      return breakItems;
    })
  );

  get timeIntervalsGroup() {
    return timeIntervalsGroup;
  }

  getPossibleBreaksForCurrentUserInUnixTimeArray$: Observable<
    { start: number; stop: number }[]
  > = this._getCurrentUserScheduleItems$.pipe(
    map((schedules) => {
      return schedules
        .map((schedule) => {
          if (schedule?.scheduleItemType?.presence === true) {
            return {
              start: new Date(schedule.start).getTime(),
              stop: new Date(schedule.stop).getTime(),
            };
          }
        })
        .filter((range) => range !== undefined);
    })
  );

  getPossibleBreakTimesForCurrentUser$ =
    this.getPossibleBreaksForCurrentUserInUnixTimeArray$.pipe(
      map((possibleBreakRanges) => {
        return possibleBreakRanges.map((range) => {
          const newStart = new Date(range.start);
          const newStop = new Date(range.stop);
          const timeStart = newStart.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          });

          const timeStop = newStop.toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
          });

          return { start: timeStart, stop: timeStop };
        });
      })
    );

  $possibleBreakTimesCurrentUser = toSignal(
    this.getPossibleBreakTimesForCurrentUser$
  );

  async addOrUpdateBreaks({
    scheduleItemInfosToUpdate: scheduleItemInfos = [],
    newValues,
    employeeUId,
    scheduleUId,
    scheduleItemInfosToCheckForTimeInterval2,
  }: {
    scheduleItemInfosToUpdate?: ScheduleItemInfoDto[];
    newValues?: { start: string; stop: string }; // Bei Add oder Update notwendig,
    employeeUId?: string;
    scheduleUId?: string;
    scheduleItemInfosToCheckForTimeInterval2?: ScheduleItemInfoDto[];
  }) {
    try {
      if (!employeeUId) {
        employeeUId = await firstValueFrom(this.getCurrentUserEmployeeUId$);
      }
      if (!scheduleUId) {
        scheduleUId = await firstValueFrom(this.getCurrentUserScheduleUId$);
      }

      const breakValues: BreakValues[] = mapScheduleItemInfoDtoToBreakValues({
        newValues,
        breakItemsToUpdate: scheduleItemInfos,
      });
      // #2273 Pausen mit und ohne Zeitangabe
      // Beim Hinzufügen und Updaten von Pausen muss auf TimeInterval === 2 Pausen geprüft werden. Diese sollen durch das setzen vom Attribut removeOthers: true entfernt werden falls vorhanden
      // If true -> scheduleItemInfosToCheckForTimeInterval2 Kommt aus add-break.component.ts aus dem Bereich Planung
      // If false -> Kommt aus dem Bereich Plan (Dialog oder Footer - Tag Ansicht)
      if (
        !!scheduleItemInfosToCheckForTimeInterval2 &&
        !!getBreakItemWithTimeInterval2(
          scheduleItemInfosToCheckForTimeInterval2
        )
      ) {
        breakValues.forEach((breakValue) => (breakValue.removeOthers = true));
      } else {
        await this._removeTimeInterval2Breaks(breakValues);
      }

      const response: BatchResponseArgsOfScheduleItemDtoAndBreakValues =
        await firstValueFrom(
          this._contributorScheduleService.addOrUpdateBreaks({
            employeeUId,
            scheduleUId,
            eagerLoading: 2, // Notwendig, da bei Hinzufügen oder Update die Property Key benötigt wird (key: 'BREAK')
            breakValues,
          })
        );
      return response;
    } catch (e) {
      console.error(
        'Error in PageCalendarGroupComponent at addOrUpdateBreaks(): ',
        e
      );
    }
  }

  async updateGroupPlanViewAfterAddOrUpdateBreaks(
    response: BatchResponseArgsOfScheduleItemDtoAndBreakValues
  ) {
    const completeScheduleFrame = await firstValueFrom(this.schedules$);
    const scheduleUId = await firstValueFrom(this.getCurrentUserScheduleUId$);
    const scheduleItemInfoDtosToUpdateOrAdd = response?.successful?.map((kvp) =>
      mapScheduleItemDtoToScheduleItemInfoDto(kvp?.value)
    );
    await this._updateViewAfterAddOrUpdateBreaks({
      completeScheduleFrame: structuredClone(completeScheduleFrame),
      scheduleUId,
      scheduleItemInfos: scheduleItemInfoDtosToUpdateOrAdd,
    });
  }

  async removeBreaks(
    scheduleItemInfos?: ScheduleItemInfoDto[],
    employeeUId?: string,
    scheduleUId?: string
  ) {
    try {
      if (!employeeUId) {
        employeeUId = await firstValueFrom(this.getCurrentUserEmployeeUId$);
      }
      if (!scheduleUId) {
        scheduleUId = await firstValueFrom(this.getCurrentUserScheduleUId$);
      }

      const response: BatchResponseArgsOfScheduleItemDtoAndScheduleItemDto =
        await firstValueFrom(
          this._contributorScheduleService.deleteBreaks({
            employeeUId,
            scheduleUId,
            scheduleItemInfos,
          })
        );

      return response;
    } catch (e) {
      console.error(
        'Error in PageCalendarGroupComponent at removeBreaks(): ',
        e
      );
    }
  }

  async updateGroupPlanViewAfterRemoveBreaks(
    response: BatchResponseArgsOfScheduleItemDtoAndScheduleItemDto
  ) {
    const completeScheduleFrame = await firstValueFrom(this.schedules$);
    const scheduleUId = await firstValueFrom(this.getCurrentUserScheduleUId$);
    const scheduleItemInfoDtosToRemove = response?.successful?.map((kvp) =>
      mapScheduleItemDtoToScheduleItemInfoDto(kvp?.key)
    );
    await this._updateViewAfterRemoveBreaks({
      completeScheduleFrame: structuredClone(completeScheduleFrame),
      scheduleUId,
      scheduleItemInfos: scheduleItemInfoDtosToRemove,
    });
  }

  async openBreakTimeDialog({ mobile }: { mobile?: boolean }) {
    let config: DialogConfig = {
      canClose: true,
      hasBackdrop: true,
      backdropClass: 'transparent',
    };

    if (mobile) {
      config.width = '100%';
      config.panelClass = 'animated-slide-in';
      config.positionStrategy = this._overlayPositionBuilder
        .global()
        .bottom('0');
      config.canClose = false
    }

    this._modal.open<BreakTimesDialogComponent, BreakTimesDialogData>({
      title: this._transloco.translate('planning-dialog-break'),
      content: BreakTimesDialogComponent,
      config,
      data: {
        date: this.selectDate(),
        actions: [
          {
            label: this._transloco.translate('new'),
            displayIcon: 'add',
            type: 'add',
            callbackFn: ({ start, stop }: { start: string; stop: string }) =>
              this._addOrUpdateFromDialog({ start, stop }),
          },
          {
            displayIcon: 'edit',
            type: 'edit',
            callbackFn: ({
              start,
              stop,
              scheduleItemInfo,
            }: {
              start: string;
              stop: string;
              scheduleItemInfo: ScheduleItemInfoDto;
            }) =>
              this._addOrUpdateFromDialog({ start, stop, scheduleItemInfo }),
          },
          {
            displayIcon: 'delete',
            type: 'remove',
            callbackFn: async ({
              scheduleItemInfo,
            }: {
              scheduleItemInfo: ScheduleItemInfoDto;
            }) => {
              const removeRes = await this.removeBreaks([scheduleItemInfo]);
              await this.updateGroupPlanViewAfterRemoveBreaks(removeRes);
            },
          },
        ],
        userBreakItems$: this.getCurrentUserBreakItems$,
        allBreakItems$: this.allBreakItemsForDayViewFromScheduleFrame$,
        timeOptions$: this.$possibleBreakTimesCurrentUser(),
      },
    });
  }

  // Add or Update Breaks from within the Dialog
  private async _addOrUpdateFromDialog({
    start,
    stop,
    scheduleItemInfo,
  }: {
    start: string;
    stop: string;
    scheduleItemInfo?: ScheduleItemInfoDto;
  }) {
    let currentUserBreakItems = await firstValueFrom(
      this.getCurrentUserBreakItems$
    );
    const selectedDate = await firstValueFrom(this.selectedDate$);
    const startTime: Time = mapStringToTime(start);
    const stopTime: Time = mapStringToTime(stop);

    // If Update: Exclude ScheduleItemInfo to update from currentUserBreakItems if it exists so it won't get removed
    if (!!scheduleItemInfo) {
      currentUserBreakItems = currentUserBreakItems.filter(
        (currentUserBreakItem) =>
          currentUserBreakItem.uId !== scheduleItemInfo.uId
      );
    }

    const itemsWithinTimespan = filterBreakItemsByTimespan({
      breakItems: currentUserBreakItems,
      start: startTime,
      stop: stopTime,
    });

    // Wenn bereits Pausen in der gegebenen Zeitspanne existieren, müssen diese zuerst entfernt werden bevor die neue Pause hinzugefügt oder die ausgewählte Pause geupdated wird
    if (itemsWithinTimespan?.length > 0) {
      const removeResponse = await this.removeBreaks(itemsWithinTimespan);
      await this.updateGroupPlanViewAfterRemoveBreaks(removeResponse);
    }

    const response = await this.addOrUpdateBreaks({
      scheduleItemInfosToUpdate: !!scheduleItemInfo ? [scheduleItemInfo] : [],
      newValues: {
        start: getISODateByTimeInterval({ selectedDate, time: startTime }),
        stop: getISODateByTimeInterval({
          selectedDate,
          time: stopTime,
        }),
      },
    });

    await this.updateGroupPlanViewAfterAddOrUpdateBreaks(response);
  }

  // To Update the View (Footer) if a Break gets added or updated
  private async _updateViewAfterAddOrUpdateBreaks({
    completeScheduleFrame,
    scheduleUId,
    scheduleItemInfos,
  }: {
    completeScheduleFrame: ContributorScheduleDto[];
    scheduleUId: string;
    scheduleItemInfos: ScheduleItemTypeInfoDto[];
  }) {
    scheduleItemInfos = scheduleItemInfos?.map((scheduleItemInfo) => {
      return {
        ...scheduleItemInfo,
        scheduleItemType: {
          ...(scheduleItemInfo as any)?.scheduleItemType?.data,
        },
      };
    });

    for (const scheduleItemInfo of scheduleItemInfos) {
      // 1. Find the current User EmployeeSchedule inside the completeScheduleFrame
      const employeeSchedule = completeScheduleFrame?.find((scheduleFrame) =>
        scheduleFrame?.schedules?.find(
          (schedule) => schedule?.schedule?.uId === scheduleUId
        )
      );

      // 2. Add or Update the ScheduleItemInfo inside the EmployeeSchedule
      employeeSchedule.schedules = employeeSchedule.schedules.map(
        (schedule) => {
          const updateItem = schedule?.items?.findIndex(
            (scheduleItemInfoDto) =>
              scheduleItemInfoDto?.uId === scheduleItemInfo?.uId
          );

          if (updateItem !== -1) {
            // Update
            schedule.items[updateItem] = scheduleItemInfo;
          } else {
            // Add
            schedule.items = [
              ...(schedule.items || []),
              scheduleItemInfo,
            ] as any;
          }
          return schedule;
        }
      );
    }

    // 3. PatchState with the new Copy of the completeScheduleFrame
    this.changeSchedules$.next([...completeScheduleFrame]);
  }

  // To Update the View (Footer) if a Break removed
  private async _updateViewAfterRemoveBreaks({
    completeScheduleFrame,
    scheduleUId,
    scheduleItemInfos,
  }: {
    completeScheduleFrame: ContributorScheduleDto[];
    scheduleUId: string;
    scheduleItemInfos: ScheduleItemTypeInfoDto[];
  }) {
    for (const scheduleItemInfo of scheduleItemInfos) {
      // 1. Find the current User EmployeeSchedule inside the completeScheduleFrame
      const employeeSchedule = completeScheduleFrame?.find((scheduleFrame) =>
        scheduleFrame?.schedules?.find(
          (schedule) => schedule?.schedule?.uId === scheduleUId
        )
      );

      // 2. Remove the ScheduleItemInfo inside the EmployeeSchedule
      employeeSchedule.schedules = employeeSchedule.schedules.map(
        (schedule) => {
          schedule.items = schedule.items.filter(
            (scheduleItemInfoDto) =>
              scheduleItemInfoDto.uId !== scheduleItemInfo.uId
          );
          return schedule;
        }
      );
    }
    // 3. PatchState with the new Copy of the completeScheduleFrame
    this.changeSchedules$.next([...completeScheduleFrame]);
  }

  private async _removeTimeInterval2Breaks(breakValues: BreakValues[]) {
    const scheduleItemInfosFromUser = await firstValueFrom(
      this._getCurrentUserScheduleItems$
    );

    const breakItemWithTimeInterval2 = getBreakItemWithTimeInterval2(
      scheduleItemInfosFromUser
    );

    if (!!breakItemWithTimeInterval2) {
      breakValues.forEach((breakValue) => (breakValue.removeOthers = true));

      // 1. Kopie des aktuellen Schedules holen
      const schedules = (await firstValueFrom(this.schedules$)).map(
        (schedule) => schedule
      );

      // 2. Pause mit timeInterval2 lokal aus der schedule$ entfernen
      const newSchedules = schedules.map((schedule) => {
        const scheduleCopy = { ...schedule };
        scheduleCopy.schedules = schedule.schedules.map((scheduleItem) => {
          const scheduleItemCopy = { ...scheduleItem };
          scheduleItemCopy.items = scheduleItem?.items?.filter(
            (item) => item.uId !== breakItemWithTimeInterval2.uId
          );
          return scheduleItemCopy;
        });
        return scheduleCopy;
      });
      // 3. Schedules Updaten
      this.schedules$.next(newSchedules);
    }
  }

  getBreakValuesForUpsertAndRemove({
    start,
    stop,
    existingBreakItemForCurrentUser,
    type,
  }: {
    start: string;
    stop: string;
    existingBreakItemForCurrentUser:
      | ScheduleItemInfoDto
      | ScheduleItemInfoDto[];
    type: 'add' | 'remove';
  }): Array<{
    scheduleItemInfos?: ScheduleItemInfoDto[];
    newValues?: { start: string; stop: string };
  }> {
    const startDate = moment(start);
    const stopDate = moment(stop);

    if (type === 'add' && existingBreakItemForCurrentUser instanceof Array) {
      if (existingBreakItemForCurrentUser.length === 0) {
        return [{ newValues: { start, stop } }];
      }

      if (
        isNewBreakInBetweenExsistingBreakes({
          existingBreakItemForCurrentUser,
          start,
          stop,
        })
      ) {
        const breakWithErliestStart = existingBreakItemForCurrentUser.find(
          (item) => moment(item.start).isBefore(startDate)
        );
        const breakWithLastestStop = existingBreakItemForCurrentUser.find(
          (item) => moment(item.stop).isAfter(stopDate)
        );

        // return combined breaks if the new break is in between two existing breaks
        return [
          {
            scheduleItemInfos: [existingBreakItemForCurrentUser[0]],
            newValues: {
              start: breakWithErliestStart.start,
              stop: breakWithLastestStop.stop,
            },
          },
          {
            scheduleItemInfos: [existingBreakItemForCurrentUser[1]],
          },
        ];
      }

      if (existingBreakItemForCurrentUser.length === 1) {
        const existingBreakItem = existingBreakItemForCurrentUser.find(
          (_) => true
        );

        if (moment(existingBreakItem.start).isSame(stopDate)) {
          // add new break at the end of the existing break
          return [
            {
              scheduleItemInfos: [existingBreakItem],
              newValues: { start, stop: existingBreakItem.stop },
            },
          ];
        } else if (moment(existingBreakItem.stop).isSame(startDate)) {
          // add new break at the start of the existing break
          return [
            {
              scheduleItemInfos: [existingBreakItem],
              newValues: { start: existingBreakItem.start, stop },
            },
          ];
        }
      }
    } else if (
      type === 'remove' &&
      !(existingBreakItemForCurrentUser instanceof Array)
    ) {
      if (
        isExistingBreakEqualOrInBetween({
          existingBreakItemForCurrentUser,
          start,
          stop,
        })
      ) {
        return [{ scheduleItemInfos: [existingBreakItemForCurrentUser] }];
      }

      const isBreakToRemoveAtStart = isBreakAtStart(
        existingBreakItemForCurrentUser,
        start
      );
      const isBreakToRemoveAtEnd = isBreakAtTheEnd(
        existingBreakItemForCurrentUser,
        stop
      );
      if (isBreakToRemoveAtEnd || isBreakToRemoveAtStart) {
        // remove the break at the start or end
        return [
          {
            scheduleItemInfos: [existingBreakItemForCurrentUser],
            newValues: isBreakToRemoveAtStart
              ? { start: stop, stop: existingBreakItemForCurrentUser.stop }
              : { start: existingBreakItemForCurrentUser.start, stop: start },
          },
        ];
      } else {
        // split the break into two breaks
        return [
          {
            scheduleItemInfos: [existingBreakItemForCurrentUser],
            newValues: {
              start: existingBreakItemForCurrentUser.start,
              stop: start,
            },
          },
          {
            newValues: {
              start: stop,
              stop: existingBreakItemForCurrentUser.stop,
            },
          },
        ];
      }
    }
  }
}
